import { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Section from "../components/Section";
import { useSnackbar } from "react-simple-snackbar";
import Row from "../components/Row";
import Column from "../components/Column";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { ArrowBackOutlined, CloseOutlined, Refresh } from "@mui/icons-material";
import { UserContext } from "../context/UserContext";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/esm/Table";
import Loader from "../components/Loader";

const Resident = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [loading, setLoading] = useState(true);
	const [actionLoading, setActionLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [passwordLoading, setPasswordLoading] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showPasswordModal, setShowPasswordModal] = useState(false);
	const [showResetPassword, setShowResetPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [resident, setResident] = useState(null);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [msisdn, setMsisdn] = useState("");
	const [residentCode, setResidentCode] = useState("");
	const [residentType, setResidentType] = useState("");
	const [units, setUnits] = useState([]);
	const [password, setPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [showUnitModal, setShowUnitModal] = useState(false);
	const [codeLoading, setCodeLoading] = useState(false);

	const [unitToDelete, setUnitToDelete] = useState(null);
	const [unitsToDelete, setUnitsToDelete] = useState([]);

	const [unitQuery, setUnitQuery] = useState("");
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [fromSuggestions, setFromSuggestions] = useState(false);
	const [loadingSuggestions, setLoadingSuggestions] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [items, setItems] = useState([]);

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getResident = () => {
		fetch(`${process.env.REACT_APP_SERVER_URI}/residents/r/${id}`)
			.then((res) => res.json())
			.then((res) => {
				setResident(res.resident);
				if (res.resident && res.resident.resident_units) {
					const existingUnits = res.resident.resident_units.map(
						(residentUnit, index) => ({
							new: false,
							index: index + 1,
							id: residentUnit.unit.id,
							tower: residentUnit.unit.tower,
							unit: residentUnit.unit.name,
						})
					);
					setUnits(existingUnits);
				}
				setLoading(false);
			});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		setSaveLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				name: name,
				email: email,
				msisdn: msisdn,
				resident_code: residentCode,
				resident_type: residentType,
				password: password,
				units: units.filter((unit) => unit.new === true),
				delete_units: unitsToDelete,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/residents/r/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			if (data.created) {
				navigate(`/pmo/residents/${data.resident.id}${window.location.search}`);
			}
			setResident(data.resident);
			setUnitsToDelete([]);
			successSnackbar(data.msg);
		}
		setActionLoading(false);
		setSaveLoading(false);
	};

	const changePassword = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		setPasswordLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				password: newPassword,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/residents/r/${id}/password`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			successSnackbar(data.msg);
			setShowPasswordModal(false);
			setNewPassword("");
		}
		setActionLoading(false);
		setPasswordLoading(false);
	};

	const generateResidentCode = async () => {
		setCodeLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/residents/codes`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setResidentCode(data.code);
		}
		setCodeLoading(false);
	};

	const deleteResident = async () => {
		setActionLoading(true);
		setDeleteLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/residents/r/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			navigate(`/pmo/residents${window.location.search}`);
			successSnackbar(data.msg);
		}
		setShowDeleteModal(false);
		setActionLoading(false);
		setDeleteLoading(false);
	};

	const deleteUnit = () => {
		setActionLoading(true);
		if (unitToDelete.id) {
			setUnitsToDelete((prevUnits) => [...prevUnits, unitToDelete.id]);
		}
		setUnits(units.filter((spa) => spa.index !== unitToDelete.index));
		setActionLoading(false);
		setUnitToDelete(null);
	};

	const getSuggestions = async () => {
		setLoadingSuggestions(true);
		const requestOptions = { method: "GET" };

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/residents/r_search?q=${unitQuery}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong");
			setShowSuggestions(false);
		} else {
			setSelectedItem(null);
			setItems(data.results);
		}
		setLoadingSuggestions(false);
	};

	const handleSuggestionHover = () => {
		setFromSuggestions(true);
		setSelectedItem(null);
	};

	const highlightSuggestion = (e) => {
		if (e.keyCode === 40) {
			e.preventDefault();
			setFromSuggestions(true);
			if (items.length !== 0) {
				if (selectedItem !== null && selectedItem < items.length - 1) {
					setSelectedItem((prevSelectedItem) => prevSelectedItem + 1);
				} else {
					setSelectedItem(0);
				}
			} else {
				setSelectedItem(null);
			}
		} else if (e.keyCode === 38) {
			e.preventDefault();
			setFromSuggestions(true);
			if (items.length !== 0) {
				if (selectedItem !== null && selectedItem !== 0) {
					setSelectedItem((prevSelectedItem) => prevSelectedItem - 1);
				} else {
					setSelectedItem(items.length - 1);
				}
			} else {
				setSelectedItem(null);
			}
		} else {
			setFromSuggestions(false);
		}
	};

	const addUnit = (e, unit) => {
		e.preventDefault();
		if (unit === null) {
			dangerSnackbar("Please select an existing unit.");
		} else {
			if (
				!units ||
				units.filter((unitFilter) => unitFilter.id === unit.id).length === 0
			) {
				setUnits((prevUnits) => [
					...prevUnits,
					{
						new: true,
						index: units.length + 1,
						id: unit.id,
						tower: unit.tower,
						unit: unit.name,
					},
				]);
			}
		}
		setUnitQuery("");
		setItems([]);
		setShowSuggestions(false);
		setSelectedItem(null);
		setShowUnitModal(false);
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			getResident();
		}
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		if (resident) {
			setName(resident.name);
			setEmail(resident.email);
			setMsisdn(resident.msisdn);
			setResidentCode(resident.resident_code);
			setResidentType(resident.resident_type);
		}
	}, [resident]);

	useEffect(() => {
		if (!fromSuggestions) {
			if (unitQuery !== "") {
				setShowSuggestions(true);
				const timer = setTimeout(() => {
					getSuggestions();
				}, 500);
				return () => clearTimeout(timer);
			} else {
				setShowSuggestions(false);
				setLoadingSuggestions(true);
			}
		}
	}, [unitQuery]);

	useEffect(() => {
		if (selectedItem !== null) {
			setUnitQuery(
				`${items[selectedItem].tower} - ${items[selectedItem].name}`
			);
		}
	}, [selectedItem]);

	return (
		<>
			<div className="page-header flex-row-left">
				<Link
					to={`/pmo/residents${window.location.search}`}
					className="flex-row-left"
				>
					<ArrowBackOutlined
						className="back-icon blue-text me-2"
						sx={{ fontSize: 28 }}
					/>
					{loading ? (
						""
					) : (
						<span className="back-label default-text">
							{resident
								? `Residents > ${resident.name}`
								: "Residents > Create New"}
						</span>
					)}
				</Link>
			</div>
			<Section className="content">
				{loading ? (
					<div className="content-loading flex-column">Loading</div>
				) : (
					<div className="info-container white-bg p-5">
						<Form onSubmit={handleSubmit}>
							<span className="section-title mb-3 block">Resident Info</span>
							<Row className="mb-4">
								<Column>
									<Form.Group>
										<Form.Label>Full Name</Form.Label>
										<Form.Control
											required
											type="text"
											className="text-sm capitalize"
											size="lg"
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
									</Form.Group>
								</Column>
							</Row>
							<Row className="mb-4">
								<Column>
									<Form.Group>
										<Form.Label>Email</Form.Label>
										<Form.Control
											type="email"
											className="text-sm"
											size="lg"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</Form.Group>
								</Column>
								<Column>
									<Form.Group>
										<Form.Label>Mobile Number</Form.Label>
										<Form.Control
											type="number"
											className="text-sm"
											size="lg"
											value={msisdn}
											onChange={(e) => setMsisdn(e.target.value)}
										/>
									</Form.Group>
								</Column>
							</Row>
							<Row className="mb-4">
								<Column>
									<Form.Group>
										<Form.Label>Resident Type</Form.Label>
										<Form.Select
											required
											value={residentType}
											onChange={(e) => setResidentType(e.target.value)}
										>
											<option value=""></option>
											<option value="AO">AO</option>
											<option value="LTT">LTT</option>
											<option value="ICE">ICE</option>
											<option value="DE">DE</option>
										</Form.Select>
									</Form.Group>
								</Column>
							</Row>
							<Row className="mb-4">
								<Column>
									<Form.Group>
										<Form.Label>Resident Code</Form.Label>
										<div className="flex-row-left">
											<Form.Control
												type="number"
												className="text-sm"
												size="lg"
												value={residentCode}
												onChange={(e) => setResidentCode(e.target.value)}
											/>
											<Button
												className="resident-code-btn flex-column"
												disabled={codeLoading}
												onClick={generateResidentCode}
											>
												{codeLoading ? (
													<span className="code-loader" />
												) : (
													<Refresh sx={{ fontSize: 18 }} />
												)}
											</Button>
										</div>
									</Form.Group>
								</Column>
							</Row>
							{!resident && (
								<Row className="mb-4">
									<Column>
										<Form.Group>
											<Form.Label>Temporary Password</Form.Label>
											<div>
												<div
													className="show-password-btn flex-row-right"
													onClick={() => setShowPassword(!showPassword)}
												>
													<span>{showPassword ? "Hide" : "Show"}</span>
												</div>
												<Form.Control
													required
													type={showPassword ? "text" : "password"}
													className="text-sm"
													size="lg"
													value={password}
													onChange={(e) => setPassword(e.target.value)}
												/>
											</div>
										</Form.Group>
									</Column>
								</Row>
							)}
							<Row className="mb-4">
								<Column>
									<Form.Label>Units</Form.Label>
									<Table className="tenant-table spas">
										<thead>
											<tr className="text-sm">
												<th>Tower</th>
												<th>Unit</th>
												<th className="tenant-action-col"></th>
											</tr>
										</thead>
										<tbody>
											{units?.map((unit) => (
												<tr key={unit.index} className="text-sm">
													<td>{unit.tower}</td>
													<td>{unit.unit}</td>
													<td className="tenant-action-col">
														<a
															href="#"
															className="tenant-action-btn text-danger"
														>
															<CloseOutlined
																sx={{ fontSize: 20 }}
																onClick={() => setUnitToDelete(unit)}
															/>
														</a>
													</td>
												</tr>
											))}
										</tbody>
									</Table>
									<div className="full flex-row-right">
										<Button
											className="tenant-form-btn"
											onClick={() => setShowUnitModal(true)}
										>
											Add Unit
										</Button>
									</div>
								</Column>
							</Row>
							<div className="form-btn-container flex-row-right">
								{resident && (
									<>
										<Button
											variant="outline-danger text-sm"
											disabled={actionLoading}
											onClick={() => setShowPasswordModal(true)}
										>
											Reset Password
										</Button>
										<Button
											variant="outline-danger ms-3 text-sm"
											disabled={actionLoading}
											onClick={() => setShowDeleteModal(true)}
										>
											Delete
										</Button>
									</>
								)}
								<Button
									type="submit"
									variant="primary ms-3 text-sm"
									disabled={actionLoading}
								>
									{saveLoading ? "Saving" : resident ? "Save Changes" : "Save"}
								</Button>
							</div>
						</Form>
					</div>
				)}
			</Section>
			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Delete
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">
						Are you sure you want to delete this resident?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setShowDeleteModal(false)}
					>
						Close
					</Button>
					<Button
						variant="danger"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={deleteResident}
					>
						{deleteLoading ? "Loading" : "Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={unitToDelete} onHide={() => setUnitToDelete(null)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Delete Resident Unit
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">
						Are you sure you want to remove this unit from this resident?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setUnitToDelete(null)}
					>
						Close
					</Button>
					<Button
						variant="danger"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={deleteUnit}
					>
						{deleteLoading ? "Loading" : "Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showPasswordModal}
				onHide={() => setShowPasswordModal(false)}
			>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Reset Password
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={changePassword}>
					<Modal.Body className="pt-4 pb-4">
						<p className="text-sm">
							The resident will be asked to change their password when they log
							in for the first time.
						</p>
						<Form.Group>
							<div
								className="show-password-btn flex-row-right"
								onClick={() => setShowResetPassword(!showResetPassword)}
							>
								<span>{showResetPassword ? "Hide" : "Show"}</span>
							</div>
							<Form.Control
								required
								type={showResetPassword ? "text" : "password"}
								className="text-sm password-text"
								size="lg"
								placeholder="New Password"
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => setShowPasswordModal(false)}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="danger"
							className="modal-btn bold-text text-sm"
							disabled={actionLoading}
						>
							{passwordLoading ? "Loading" : "Reset"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showUnitModal} onHide={() => setShowUnitModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Add Resident Unit
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={(e) => addUnit(e, items[selectedItem])}>
					<Modal.Body className="spa-search-modal pt-4 pb-4">
						<Form.Group>
							<Form.Label>Unit</Form.Label>
							<div className="spa-input-container">
								<Form.Control
									autoFocus
									required
									type="text"
									className="text-sm"
									size="lg"
									placeholder="Search units"
									value={unitQuery}
									onChange={(e) => setUnitQuery(e.target.value)}
									onKeyDown={highlightSuggestion}
								/>
								<div
									className={`spa-suggestions-container ${
										!showSuggestions && "hidden"
									}`}
								>
									{loadingSuggestions ? (
										<Loader className="suggestions-loader white-bg" />
									) : (
										items.map((item, index) => (
											<a
												key={index}
												className={`suggestion ellipsis ${
													selectedItem === index && "selected"
												}`}
												onMouseOver={handleSuggestionHover}
												onClick={(e) => addUnit(e, item)}
											>
												<span>
													{item.tower} - {item.name}
												</span>
											</a>
										))
									)}
								</div>
							</div>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => setShowUnitModal(false)}
						>
							Cancel
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Resident;
