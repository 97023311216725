import { useEffect, useState, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { Refresh, Search } from "@mui/icons-material";
import Section from "../components/Section";
import Table from "react-bootstrap/Table";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const PoolAccess = () => {
	const [user] = useContext(UserContext);
	const [logs, fetchLogs] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searching, setSearching] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [name, setName] = useState(searchParams.get("q") || "");
	const [searchDate, setSearchDate] = useState(searchParams.get("date") || "");
	const [searchName, setSearchName] = useState(searchParams.get("name") || "");
	const [searchType, setSearchType] = useState(searchParams.get("type") || "");

	const searchFields = [
		{ key: "date", value: searchDate },
		{ key: "name", value: searchName },
		{ key: "type", value: searchType },
	];

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = () => {
		setLoading(true);
		fetch(
			`${process.env.REACT_APP_SERVER_URI}/guests/pool/logs${window.location.search}`
		)
			.then((res) => res.json())
			.then((res) => {
				fetchLogs(res.data);
				fetchMeta(res.meta);
				setLoading(false);
				setSearching(false);
			});
	};

	useEffect(() => {
		document.title = "Azure | Pool Access Logs";
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			var newSearch = { ...searchParams };

			searchFields.map((field) => {
				if (field.value !== "") {
					newSearch[field.key] = field.value;
				} else {
					delete newSearch[field.key];
				}
			});
			setSearchParams(newSearch);
		}, 500);
		return () => clearTimeout(timer);
	}, [searchDate, searchName, searchType]);

	useEffect(() => {
		getData();
	}, [searchParams]);

	return (
		<>
			<div className="page-header flex-row-left">
				<div className="search-container flex-row-left">
					<Search className="search-icon" sx={{ fontSize: 22 }} />
					<input
						type="text"
						className="search-text form-control"
						placeholder="Search pool logs"
					/>
				</div>
			</div>
			<Section className="content">
				<div className="content-header mb-2 flex-row-right">
					<Pagination
						meta={meta}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						searchFields={searchFields}
						className="pull-right flex-row-left"
					/>
					<Refresh
						className="refresh-btn blue-text ms-2 pointer"
						sx={{ fontSize: 28 }}
						onClick={getData}
					/>
				</div>
				<Table className="content-table white-bg">
					<thead className="blue-text">
						<tr>
							<th className="col-2">Date</th>
							<th className="col-2">Name</th>
							<th className="col-2">Type</th>
							<th className="col-2">Entry</th>
							<th className="col-2">Exit</th>
						</tr>
						<tr className="search-tr">
							<th>
								<Form.Control
									type="date"
									onChange={(e) => setSearchDate(e.target.value)}
									value={searchDate}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchName}
									onChange={(e) => setSearchName(e.target.value)}
								/>
							</th>
							<th>
								<Form.Select
									className="form-control"
									value={searchType}
									onChange={(e) => setSearchType(e.target.value)}
								>
									<option value=""></option>
									<option value="STL">STL</option>
									<option value="Non-STL">Non-STL</option>
								</Form.Select>
							</th>
							<th>
								<Form.Control disabled />
							</th>

							<th>
								<Form.Control disabled />
							</th>
						</tr>
					</thead>
					{loading ? (
						<Loader className="content-loader white-bg" />
					) : logs && logs.length != 0 ? (
						<tbody>
							{logs
								? logs.map((log) => (
										<tr key={log.id}>
											<td>
												{format(new Date(log.created_at), "MMM dd, yyyy")}
											</td>
											<td>{log.name}</td>
											<td>{log.type}</td>
											<td>{format(new Date(log.entry), "hh:mm a")}</td>
											<td>
												{log.exit ? format(new Date(log.exit), "hh:mm a") : ""}
											</td>
										</tr>
								  ))
								: ""}
						</tbody>
					) : (
						<div className="empty-content white-bg flex-column">
							<span>No records found.</span>
						</div>
					)}
				</Table>
			</Section>
		</>
	);
};

export default PoolAccess;
