import coloredLogo from "../assets/logo_colored.png";
import Container from "../components/Container";
import Terms from "../components/Terms";

const TermsPage = () => {
	return (
		<div className="terms-content pt-5 pb-5">
			<Container>
				<div className="align-center">
					<img src={coloredLogo} alt="" className="privacy-logo" />
					<h2 className="bold-text text-xl">Terms and Conditions</h2>
				</div>
				<br />
				<Terms />
			</Container>
		</div>
	);
};

export default TermsPage;
