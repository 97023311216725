import Button from "react-bootstrap/esm/Button";

const Guest = ({ guest, setGuestToEdit }) => {
	return (
		<tr>
			<td>{guest.Guest.visitor_pass}</td>
			<td>{guest.Guest.name}</td>
			<td>{guest.Booking.unit.tower}</td>
			<td>{guest.Booking.unit.name}</td>
			<td className="align-center">
				<Button
					variant="primary"
					className="booking-action-btn"
					onClick={() => setGuestToEdit(guest)}
				>
					Check Out
				</Button>
			</td>
		</tr>
	);
};

export default Guest;
