import { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { ArrowBackOutlined } from "@mui/icons-material";
import { format } from "date-fns";
import { UserContext } from "../context/UserContext";
import { useSnackbar } from "react-simple-snackbar";
import Section from "../components/Section";
import Container from "../components/Container";
import Row from "../components/Row";
import Column from "../components/Column";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/esm/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import BookingImg from "../components/BookingImg";

const Revision = ({ access }) => {
	let { id } = useParams();
	const selfCheckinRef = useRef(null);
	const selfCheckoutRef = useRef(null);
	const [user] = useContext(UserContext);
	const [loading, setLoading] = useState(true);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [rejectLoading, setRejectLoading] = useState(false);
	const [showImage, setShowImage] = useState(null);
	const [showRejectModal, setShowRejectModal] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [booking, fetchBooking] = useState([]);
	const [primaryGuest, fetchPrimaryGuest] = useState([]);
	const [bookingRemarks, setBookingRemarks] = useState("");

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = () => {
		fetch(`${process.env.REACT_APP_SERVER_URI}/bookings/revisions/${id}`)
			.then((res) => res.json())
			.then((res) => {
				fetchBooking(res.data);
				fetchPrimaryGuest(res.primary_guest);
				setLoading(false);
			});
	};

	const changeStatus = async (e, action) => {
		e.preventDefault();
		if (action == "confirm") {
			var loadBtn = setConfirmLoading;
		} else {
			var loadBtn = setRejectLoading;
		}

		setActionLoading(true);
		loadBtn(true);

		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				remarks: bookingRemarks,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/revisions/${booking.id}/${action}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			fetchBooking(data.booking);
			successSnackbar(data.msg);
		}
		setActionLoading(false);
		setShowRejectModal(false);
		setShowConfirmModal(false);
		loadBtn(false);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<>
			<div className="page-header flex-row-left">
				<Link
					to={`/pmo/revisions${window.location.search}`}
					className="flex-row-left"
				>
					<ArrowBackOutlined
						className="back-icon blue-text me-2"
						sx={{ fontSize: 28 }}
					/>
					{loading ? (
						""
					) : (
						<>
							<span className="revision-title bold-text default-text">
								Revision
							</span>
							<span className="back-label default-text">
								{booking.ref_no} | {booking.primary_guest_name}
							</span>
						</>
					)}
				</Link>
			</div>
			<Section className="content">
				{loading ? (
					<div className="loading flex-column">Loading</div>
				) : (
					<div className="info-container white-bg p-5">
						<Container>
							<Row className="mb-3">
								<Column className="p-0">
									<span className="guests-header">Booking Details</span>
								</Column>
							</Row>
							<Row>
								<Column className="info-col">
									<label className="form-label">Reference Number</label>
									<span className="info-text">{booking.ref_no}</span>
								</Column>
								<Column className="info-col">
									<label className="form-label">Date Requested</label>
									<span className="info-text">
										{format(
											new Date(booking.created_at),
											"MMM dd, yyyy (hh:mm a)"
										)}
									</span>
								</Column>
								<Column className="info-col">
									<label className="form-label">Status</label>
									<span className="info-text">{booking.revision_status}</span>
								</Column>
							</Row>
							<Row>
								<Column className="info-col">
									<label className="form-label">Arrival</label>
									<span className="info-text">
										{format(
											new Date(booking.arrival),
											"MMM dd, yyyy (hh:mm a)"
										)}
									</span>
								</Column>
								<Column className="info-col">
									<label className="form-label">Departure</label>
									<span className="info-text">
										{format(
											new Date(booking.departure),
											"MMM dd, yyyy (hh:mm a)"
										)}
									</span>
								</Column>
							</Row>
							<Row>
								<Column className="info-col">
									<label className="form-label">Adults</label>
									<span className="info-text">{booking.adults}</span>
								</Column>
								<Column className="info-col">
									<label className="form-label">Children</label>
									<span className="info-text">{booking.children}</span>
								</Column>
								<Column className="info-col">
									<label className="form-label">Self Check In</label>
									<span className="info-text">
										{/* {booking.self_checkin ? "Yes" : "No"} */}
										<input
											ref={selfCheckinRef}
											type="checkbox"
											className="cbx hidden"
											defaultChecked={booking.self_checkin}
											id="selfCheckin"
											disabled={true}
										/>
										<label htmlFor="selfCheckin" className="lbl"></label>
									</span>
								</Column>
								<Column className="info-col">
									<label className="form-label">Self Check Out</label>
									<span className="info-text">
										{/* {booking.self_checkout ? "Yes" : "No"} */}
										<input
											ref={selfCheckoutRef}
											type="checkbox"
											className="cbx hidden"
											defaultChecked={booking.self_checkout}
											id="selfCheckout"
											disabled={true}
										/>
										<label htmlFor="selfCheckout" className="lbl"></label>
									</span>
								</Column>
							</Row>
							<Row className="mt-5 mb-3">
								<Column className="p-0">
									<span className="guests-header">Unit Owner/SPA Details</span>
								</Column>
							</Row>
							<Row>
								<Column className="info-col">
									<label className="form-label">Owner/SPA Name</label>
									<span className="info-text">
										{booking ? booking.tenant.name : ""}
									</span>
								</Column>
								<Column className="info-col">
									<label className="form-label">Tower & Unit</label>
									<span className="info-text">
										{booking.unit.tower} {booking.unit.name}
									</span>
								</Column>
								<Column className="info-col">
									<label className="form-label">Unit Type</label>
									<span className="info-text">{booking.unit.type}</span>
								</Column>
							</Row>
							<Row>
								<Column className="info-col">
									<label className="form-label">Onsite Contact Person</label>
									<span className="info-text">
										{booking.contact_person ? booking.contact_person : "N/A"}
									</span>
								</Column>
								<Column className="info-col">
									<label className="form-label">Contact Number</label>
									<span className="info-text">
										{booking.contact_person_msisdn
											? booking.contact_person_msisdn
											: "N/A"}
									</span>
								</Column>
							</Row>
							<Row className="mt-5 mb-3">
								<Column className="p-0">
									<span className="guests-header">Primary Guest</span>
								</Column>
							</Row>
							<div className="guest-row mb-5">
								<Row className="header-row">
									<Column>
										<span className="white-text">
											{booking.primary_guest_name}
										</span>
									</Column>
								</Row>
								<Row>
									<Column className="info-col">
										<label className="form-label">Age</label>
										<span className="info-text">{primaryGuest.age}</span>
									</Column>
									<Column className="info-col">
										<label className="form-label">Nationality</label>
										<span className="info-text">
											{primaryGuest.nationality}
										</span>
									</Column>
									<Column className="info-col">
										<label className="form-label">Status</label>
										<span className="info-text">{primaryGuest.status}</span>
									</Column>
								</Row>
								<Row>
									<Column className="info-col">
										<label className="form-label">Address</label>
										<span className="info-text">{primaryGuest.address}</span>
									</Column>
								</Row>
								<Row>
									<Column className="info-col">
										<label className="form-label">Email</label>
										<span className="info-text">{primaryGuest.email}</span>
									</Column>
									<Column className="info-col">
										<label className="form-label">Contact Number</label>
										<span className="info-text">{primaryGuest.msisdn}</span>
									</Column>
								</Row>
								<Row>
									<Column className="info-col">
										<label className="form-label">Vehicle</label>
										<span className="info-text">
											{primaryGuest.vehicle.length !== 0
												? `${primaryGuest.vehicle[0].model} (${primaryGuest.vehicle[0].type}) - ${primaryGuest.vehicle[0].plate_no}`
												: "N/A"}
										</span>
									</Column>
									<Column className="info-col">
										<label className="form-label">Parking</label>
										<span className="info-text">
											{primaryGuest.vehicle.length !== 0 &&
											primaryGuest.vehicle[0].with_parking
												? `${primaryGuest.vehicle[0].parking_tower} - ${primaryGuest.vehicle[0].parking_level}, ${primaryGuest.vehicle[0].parking_slot}`
												: "N/A"}
										</span>
									</Column>
								</Row>
								<Row>
									<Column className="info-col">
										<label className="form-label">Valid ID</label>
										{primaryGuest.id_img ? (
											<BookingImg
												className="booking-img"
												src={primaryGuest.id_img}
												setShowImage={setShowImage}
											/>
										) : (
											"N/A"
										)}
									</Column>
									<Column className="info-col">
										<label className="form-label">Vax Cert.</label>
										{primaryGuest.vax_img ? (
											<BookingImg
												className="booking-img"
												src={primaryGuest.vax_img}
												setShowImage={setShowImage}
											/>
										) : (
											"N/A"
										)}
									</Column>
								</Row>
							</div>

							{booking.guests.filter((guest) => !guest.is_primary).length >
								0 && (
								<Row className="mt-5 mb-3">
									<Column className="p-0">
										<span className="guests-header">Additional Guests</span>
									</Column>
								</Row>
							)}
							{booking.guests
								.filter((guest) => !guest.is_primary)
								.map((guest) => (
									<div className="guest-row mb-5">
										<Row key={guest.id} className="header-row">
											<Column>
												<span className="white-text">{guest.name}</span>
											</Column>
										</Row>

										<Row>
											<Column className="info-col">
												<label className="form-label">Age</label>
												<span className="info-text">{guest.age}</span>
											</Column>
											<Column className="info-col">
												<label className="form-label">Below 3 Feet</label>
												<span className="info-text">
													{guest.below_min_height ? "Yes" : "No"}
												</span>
											</Column>
											<Column className="info-col">
												<label className="form-label">Status</label>
												<span className="info-text">{guest.status}</span>
											</Column>
										</Row>
										<Row>
											<Column className="info-col">
												<label className="form-label">Vehicle</label>
												<span className="info-text">
													{guest.vehicle.length !== 0
														? `${guest.vehicle[0].model} (${guest.vehicle[0].type}) - ${guest.vehicle[0].plate_no}`
														: "N/A"}
												</span>
											</Column>
											<Column className="info-col">
												<label className="form-label">Parking</label>
												<span className="info-text">
													{guest.vehicle.length !== 0 &&
													guest.vehicle[0].with_parking
														? `${guest.vehicle[0].parking_tower} - ${guest.vehicle[0].parking_level}, ${guest.vehicle[0].parking_slot}`
														: "N/A"}
												</span>
											</Column>
										</Row>
										<Row>
											<Column className="info-col">
												<label className="form-label">Valid ID</label>
												{guest.id_img ? (
													<BookingImg
														className="booking-img"
														src={guest.id_img}
														setShowImage={setShowImage}
													/>
												) : (
													"N/A"
												)}
											</Column>
											<Column className="info-col">
												<label className="form-label">Vax Cert.</label>
												{guest.vax_img ? (
													<BookingImg
														className="booking-img"
														src={guest.vax_img}
														setShowImage={setShowImage}
													/>
												) : (
													"N/A"
												)}
											</Column>
										</Row>
									</div>
								))}
							<Row className="mt-5 mb-3">
								<Column className="p-0">
									<span className="guests-header">Swimbands</span>
								</Column>
							</Row>
							<Row>
								<Column className="info-col">
									<label className="form-label">Total Swim Bands</label>
									<span className="info-text">{booking.total_swim_bands}</span>
								</Column>
								<Column className="info-col">
									<label className="form-label">Total Amount</label>
									<span className="info-text">PHP {booking.total_amount}</span>
								</Column>
								<Column className="info-col">
									<label className="form-label">Mode of Payment</label>
									<span className="info-text">
										{booking.total_amount === 0 ? "N/A" : booking.payment_mode}
									</span>
								</Column>
							</Row>
							<Row className="mt-5 mb-3">
								<Column className="p-0">
									<span className="guests-header">Remarks</span>
								</Column>
							</Row>
							<Row>
								<Column className="info-col">
									<p className="info-text">{booking.remarks || "N/A"}</p>
								</Column>
							</Row>
							<Row>
								<Column className="action-col flex-row-right mt-5">
									{booking.revision_status == "Pending" &&
										access.bookings_edit && (
											<>
												<Button
													type="button"
													variant="outline-danger"
													className="booking-action-btn"
													onClick={() => setShowRejectModal(true)}
													disabled={actionLoading}
												>
													Reject
												</Button>
												<Button
													type="button"
													variant="primary"
													className="booking-action-btn ms-3"
													disabled={actionLoading}
													onClick={() => setShowConfirmModal(true)}
												>
													Confirm
												</Button>
											</>
										)}
								</Column>
							</Row>
						</Container>
					</div>
				)}
			</Section>
			<Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Reject Revision
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={(e) => changeStatus(e, "reject")}>
					<Modal.Body className="pt-4 pb-4">
						<p className="text-sm">
							This will also reject the booking itself, would you like to
							proceed?
						</p>
						<Form.Group className="mb-3">
							<Form.Label>Remarks</Form.Label>
							<Form.Control
								autoFocus
								required
								as="textarea"
								rows={5}
								value={bookingRemarks}
								onChange={(e) => setBookingRemarks(e.target.value)}
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => setShowRejectModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="danger"
							className="modal-btn bold-text text-sm"
							disabled={actionLoading}
						>
							{rejectLoading ? "Loading" : "Reject"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Confirm Revison
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">
						This will also confirm the booking itself, would you like to
						proceed?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setShowConfirmModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={(e) => changeStatus(e, "confirm")}
					>
						{confirmLoading ? "Loading" : "Confirm"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showImage}
				size="xl"
				className="img-modal"
				onHide={() => setShowImage(null)}
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body className="p-0 flex-column">
					<img src={showImage} className="img-open" />
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Revision;
