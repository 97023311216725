import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { TenantContext } from "../context/TenantContext";
import { UserContext } from "../context/UserContext";
import { SpaContext } from "../context/SpaContext";
import { useNavigate } from "react-router-dom";
import Section from "../components/Section";
import coloredLogo from "../assets/logo_white.png";
import { useSnackbar } from "react-simple-snackbar";
import { AccountCircle, Lock } from "@mui/icons-material";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";
import Modal from "react-bootstrap/Modal";
import Terms from "../components/Terms";

const TenantLogin = () => {
	const navigate = useNavigate();
	const [, setTenantUser] = useContext(TenantContext);
	const [spaUser, setSpaUser] = useContext(SpaContext);
	const [user, setUser] = useContext(UserContext);
	const [isLoading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [expand, setExpand] = useState(false);
	const [showTermsModal, setShowTermsModal] = useState(false);
	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		setShowTermsModal(true);
	};

	const submitLogin = async () => {
		setLoading(true);
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				email: email,
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/login${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
			setShowTermsModal(false);
			setLoading(false);
		} else {
			if (data.type === "tenant") {
				setTenantUser(data.user);
			} else if (data.type === "spa") {
				setSpaUser(data.user);
			} else {
				setUser(data.user);
			}
		}
	};

	useEffect(() => {
		if (user) {
			navigate("/pmo");
		}
	}, [user]);

	useEffect(() => {
		if (spaUser) {
			navigate("/spa");
		}
	}, [spaUser]);

	return (
		<>
			<Section className="login-bg full-page">
				<div className="login-content full full-height flex-column">
					<Container className="flex-column full">
						<img src={coloredLogo} className="tenant-login-logo" />
						<span className="white-text tenant-login-brand uppercase spaced bold-text">
							Property Management System
						</span>
						<span className="tenant-login-label bold-text white-text">
							Sign in to your account.
						</span>
						<Form onSubmit={handleSubmit} className="col-lg-4 col-sm-5 col-12">
							<div className="login-input-container flex-row-left">
								<AccountCircle
									sx={{ fontSize: 24 }}
									className="blue-text opacity-7 login-input-icon"
								/>
								<Form.Control
									type="text"
									className="login-input blue-text opacity-7"
									placeholder="Username/Email"
									autoCapitalize="off"
									autoComplete="off"
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div className="login-input-container flex-row-left">
								<Lock
									sx={{ fontSize: 22 }}
									className="blue-text opacity-7 login-input-icon"
								/>
								<Form.Control
									type="password"
									className="login-input blue-text opacity-7"
									placeholder="Password"
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							{/* <TextField
								required
								type="text"
								label="Username/Email"
								className="full tenant-login-text tenant-login-username"
								autoCapitalize="off"
								autoComplete="off"
								onChange={(e) => setEmail(e.target.value)}
							/>
							
							<TextField
								required
								type="password"
								label="Password"
								className="full tenant-login-text"
								onChange={(e) => setPassword(e.target.value)}
								autoComplete="off"
							/> */}
							<Button type="submit" className="full tenant-login-btn btn-lg">
								Sign In
							</Button>
							<div className="login-terms-container flex-column white-text">
								<p className="login-terms mb-0">
									By continuing, you agree to our
								</p>
								<p className="login-terms mb-0">
									<Link
										to="/terms"
										className="decorated medium-text white-text"
									>
										Terms and Conditions
									</Link>{" "}
									and{" "}
									<Link
										to="/privacy"
										className="decorated medium-text white-text"
									>
										Privacy Policy
									</Link>
								</p>
							</div>
						</Form>
					</Container>
				</div>
			</Section>
			<Modal
				centered
				size="lg"
				show={showTermsModal}
				onHide={() => setShowTermsModal(false)}
			>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Privacy Notice
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Terms />
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn login-modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setShowTermsModal(false)}
					>
						Disagree
					</Button>
					<Button
						variant="primary"
						className="modal-btn login-modal-btn bold-text text-sm"
						disabled={isLoading}
						onClick={submitLogin}
					>
						{isLoading ? "Logging in.." : "Agree"}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default TenantLogin;
