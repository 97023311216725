import { useState, useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import Section from "../components/Section";
import coloredLogo from "../assets/logo_colored.png";
import Button from "react-bootstrap/Button";
import Container from "../components/Container";
import Row from "../components/Row";
import Column from "../components/Column";
import { useSnackbar } from "react-simple-snackbar";
import { AccountCircleOutlined, LockOutlined } from "@mui/icons-material";

const Login = () => {
	const navigate = useNavigate();
	const [, setUser] = useContext(UserContext);
	const [isLoading, setLoading] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const submitLogin = async (e) => {
		e.preventDefault();
		setLoading(true);
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				username: username,
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/users/login`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
			setLoading(false);
		} else {
			setUser(data.user);
			navigate("/");
		}
	};

	return (
		<Section className="full-page flex-column white-bg">
			<img src={coloredLogo} className="login-logo mb-2" />
			<span className="login-brand dark-blue-text mb-4 spaced uppercase">
				Property Management System
			</span>
			<span className="login-label blue-text bold-text m-2">Log In</span>
			<form className="col-lg-4 col-sm-5 col-12 mt-3" onSubmit={submitLogin}>
				<Container>
					<Row className="mb-3">
						<Column>
							<div className="input-icon-container flex-column">
								<AccountCircleOutlined
									className="input-icon"
									sx={{ fontSize: 20 }}
								/>
							</div>
							<input
								type="text"
								className="login-text form-control"
								placeholder="Username"
								onChange={(e) => setUsername(e.target.value)}
							/>
						</Column>
					</Row>
					<Row>
						<Column>
							<div className="input-icon-container flex-column">
								<LockOutlined className="input-icon" sx={{ fontSize: 20 }} />
							</div>
							<input
								type="password"
								className="login-text form-control"
								placeholder="password"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Column>
					</Row>
					<div className="d-grid mt-4">
						<Button
							type="submit"
							variant="primary"
							className="login-btn"
							disabled={isLoading}
						>
							{isLoading ? "Logging you in.." : "Log In"}
						</Button>
					</div>
				</Container>
			</form>
		</Section>
	);
};

export default Login;
