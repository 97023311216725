import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import coloredLogo from "../assets/logo_colored.png";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Terms from "../components/Terms";

const ParkingForm = () => {
	const navigate = useNavigate();
	const [msisdn, setMsisdn] = useState("");
	const [name, setName] = useState("");
	const [tower, setTower] = useState("");
	const [unit, setUnit] = useState("");
	const [type, setType] = useState("");
	const [model, setModel] = useState("");
	const [plate, setPlate] = useState("");
	const [loading, setLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [showTermsModal, setShowTermsModal] = useState(false);

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const handleMsisdn = (e) => {
		const inputValue = e.target.value;
		if (
			inputValue === "" ||
			(/^\d+$/.test(inputValue) && inputValue.length <= 11)
		) {
			setMsisdn(inputValue);
		}
	};

	const getPrevTransaction = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/parking/reuse?msisdn=${msisdn}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("No previous transactions.");
		} else {
			setName(data.transaction.guest_display_name);
			setTower(data.transaction.tower_to_visit);
			setUnit(data.transaction.unit_to_visit);
			setType(data.transaction.vehicle_type);
			setModel(data.transaction.vehicle_model);
			setPlate(data.transaction.vehicle_plate_no);
		}
		setLoading(false);
	};

	const addTransaction = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				tower_to_visit: tower,
				unit_to_visit: unit,
				type: type,
				model: model,
				plate: plate,
				msisdn: msisdn,
				name: name,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/parking/transactions/form`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			navigate("/parking-success");
		}
		setActionLoading(false);
		setShowTermsModal(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setShowTermsModal(true);
	};

	useMemo(() => {
		if (msisdn.length === 11) {
			console.log("fetch");
			getPrevTransaction();
		}
	}, [msisdn]);

	return (
		<>
			<div className="page-section full-page bg-white">
				{loading && (
					<div className="parking-loader flex-column">
						<div className="loader-wheel" />
					</div>
				)}
				<Container className="flex-column">
					<div className="col-lg-5 col-md-7 col-12 flex-column pt-5 pb-5">
						<img
							src={coloredLogo}
							alt="logo"
							className="parking-form-logo mb-30"
						/>
						<h1 className="login-brand spaced uppercase mb-30">Parking Form</h1>
						<Form className="full" onSubmit={handleSubmit}>
							<Row className="mb-30">
								<Col>
									<Form.Label className="text-md bold-text">
										Mobile no.
									</Form.Label>
									<Form.Control
										required
										type="number"
										className="parking-input"
										value={msisdn}
										onChange={handleMsisdn}
									/>
								</Col>
							</Row>
							<Row className="mb-30">
								<Col>
									<Form.Label className="text-md bold-text mb-0">
										Full name
									</Form.Label>
									<span className="text-sm opacity-5 mb-10 block">
										Please enter your name exactly as it appears on your valid
										ID.
									</span>
									<Form.Control
										required
										className="parking-input capitalize"
										maxLength={80}
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="mb-30">
								<Col>
									<Form.Label className="text-md bold-text">
										Tower to visit
									</Form.Label>
									<Form.Select
										required
										className="parking-input"
										value={tower}
										onChange={(e) => setTower(e.target.value)}
									>
										<option value=""></option>
										<option value="Bahamas">Bahamas</option>
										<option value="Boracay">Boracay</option>
										<option value="Miami">Miami</option>
										<option value="Maldives">Maldives</option>
										<option value="Maui">Maui</option>
										<option value="Positano">Positano</option>
										<option value="Rio East">Rio East</option>
										<option value="Rio West">Rio West</option>
										<option value="Santorini">Santorini</option>
										<option value="St. Tropez">St. Tropez</option>
									</Form.Select>
								</Col>
							</Row>
							<Row className="mb-30">
								<Col>
									<Form.Label className="text-md bold-text">
										Unit to visit
									</Form.Label>
									<Form.Control
										required
										className="parking-input uppercase"
										maxLength={20}
										value={unit}
										onChange={(e) => setUnit(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="mb-30">
								<Col>
									<Form.Label className="text-md bold-text">
										Vehicle type
									</Form.Label>
									<Form.Select
										required
										className="parking-input"
										value={type}
										onChange={(e) => setType(e.target.value)}
									>
										<option value=""></option>
										<option value="Car">Car</option>
										<option value="Motorcycle">Motorcycle</option>
									</Form.Select>
								</Col>
							</Row>
							<Row className="mb-30">
								<Col>
									<Form.Label className="text-md bold-text">
										Make and model
									</Form.Label>
									<Form.Control
										required
										className="parking-input capitalize"
										maxLength={50}
										value={model}
										onChange={(e) => setModel(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="mb-50">
								<Col>
									<Form.Label className="text-md bold-text">
										Plate no.
									</Form.Label>
									<Form.Control
										required
										className="parking-input uppercase"
										maxLength={20}
										value={plate}
										onChange={(e) => setPlate(e.target.value)}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<Button type="submit" className="full btn-lg">
										Submit
									</Button>
								</Col>
							</Row>
						</Form>
					</div>
				</Container>
			</div>
			<Modal
				centered
				size="lg"
				show={showTermsModal}
				onHide={() => setShowTermsModal(false)}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Privacy Notice
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Terms />
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn login-modal-btn bold-text text-sm me-2 dismiss-btn"
						disabled={actionLoading}
						onClick={() => setShowTermsModal(false)}
					>
						Disagree
					</Button>
					<Button
						className="modal-btn login-modal-btn bold-text text-sm flex-column"
						disabled={actionLoading}
						onClick={addTransaction}
					>
						{actionLoading ? "Submitting.." : "Agree"}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ParkingForm;
