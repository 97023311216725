import { useEffect, useState, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import {
	ChevronLeftOutlined,
	ChevronRightOutlined,
	Search,
} from "@mui/icons-material";
import Section from "../components/Section";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "../components/Row";
import Column from "../components/Column";
import Container from "../components/Container";

const VehicleLogs = () => {
	const [user] = useContext(UserContext);
	const [logs, fetchLogs] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searching, setSearching] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [name, setName] = useState(searchParams.get("q") || "");

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = () => {
		fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/vehicles/logs${window.location.search}`
		)
			.then((res) => res.json())
			.then((res) => {
				fetchLogs(res.data);
				fetchMeta(res.meta);
				setLoading(false);
				setSearching(false);
				console.log(res.data);
			});
	};

	useEffect(() => {
		document.title = "Azure | Vehicle Logs";
	}, []);

	useEffect(() => {
		getData();
	}, [searchParams]);

	return (
		<>
			<div className="page-header flex-row-left">
				<div className="search-container flex-row-left">
					<Search className="search-icon" sx={{ fontSize: 22 }} />
					<input
						type="text"
						className="search-text form-control"
						placeholder="Search pool logs"
					/>
				</div>
			</div>
			<Section className="content">
				{loading ? (
					<div className="content-loading flex-column">Loading</div>
				) : (
					<>
						<div className="content-header">
							<div className="pagination-container pull-right flex-row-left">
								<span className="pagination-label">1 - 20</span>
								<span className="pagination-label ms-2 me-2">of</span>
								<span className="pagination-label">321</span>
								<Button
									variant="primary"
									className="pagination-btn flex-column ms-3 me-2"
									disabled
								>
									<ChevronLeftOutlined
										sx={{ fontSize: 32 }}
										className="blue-text"
									/>
								</Button>
								<Button
									variant="primary"
									className="pagination-btn flex-column"
								>
									<ChevronRightOutlined
										sx={{ fontSize: 32 }}
										className="blue-text"
									/>
								</Button>
							</div>
						</div>
						<Table className="content-table white-bg">
							<thead className="blue-text">
								<tr>
									<th>Date</th>
									<th>Primary Guest</th>
									<th>Booking</th>
									<th>Unit</th>
									<th>Vehicle</th>
									<th>Entry</th>
									<th>Exit</th>
								</tr>
							</thead>
							<tbody>
								{logs
									? logs.map((log) => (
											<tr key={log.id}>
												<td>
													{format(new Date(log.created_at), "MMM dd, yyyy")}
												</td>
												<td>{log.booking.primary_guest_name}</td>
												<td>{log.booking.ref_no}</td>
												<td>
													{log.booking.unit.tower} {log.booking.unit.name}
												</td>
												<td>{log.vehicle.plate_no}</td>
												<td>{format(new Date(log.entry), "hh:mm a")}</td>
												<td>
													{log.exit
														? format(new Date(log.exit), "hh:mm a")
														: ""}
												</td>
											</tr>
									  ))
									: ""}
							</tbody>
						</Table>
					</>
				)}
			</Section>
		</>
	);
};

export default VehicleLogs;
