import { useState, useEffect, useContext } from "react";
import { TenantContext } from "../context/TenantContext";
import { Skeleton } from "@mui/material";

const BookingImg = ({ className, src, setShowImage }) => {
	const [tenantUser] = useContext(TenantContext);
	const [img, setImg] = useState(null);
	const [loading, setLoading] = useState(true);

	const getImg = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": tenantUser ? tenantUser.auth_token : "",
			},
		};

		const response = await fetch(src, requestOptions);

		if (!response.ok) {
			setImg(null);
		} else {
			const blob = await response.blob();
			setImg(URL.createObjectURL(blob));
		}
		setLoading(false);
	};

	useEffect(() => {
		getImg();
	}, []);

	return loading ? (
		<Skeleton
			variant="rounded"
			width="100%"
			height={className.includes("tenant") ? 100 : 250}
			className={`booking-skeleton ${className}`}
		/>
	) : img ? (
		<div
			className={className}
			onClick={() => setShowImage(img)}
			style={{
				background: `url(${img})`,
			}}
		/>
	) : (
		"N/A"
	);
};

export default BookingImg;
