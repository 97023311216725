import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Section from "../components/Section";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { UserContext } from "../context/UserContext";
import Form from "react-bootstrap/Form";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import { Refresh } from "@mui/icons-material";

const Residents = () => {
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [residents, fetchResidents] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [searchCode, setSearchCode] = useState(searchParams.get("code") || "");
	const [searchName, setSearchName] = useState(searchParams.get("name") || "");
	const [searchType, setSearchType] = useState(searchParams.get("type") || "");
	const [searchUnit, setSearchUnit] = useState(searchParams.get("unit") || "");

	const searchFields = [
		{ key: "name", value: searchName },
		{ key: "type", value: searchType },
		{ key: "unit", value: searchUnit },
		{ key: "code", value: searchCode },
	];

	const getData = () => {
		setLoading(true);
		fetch(
			`${process.env.REACT_APP_SERVER_URI}/residents/${window.location.search}`
		)
			.then((res) => res.json())
			.then((res) => {
				fetchResidents(res.data);
				fetchMeta(res.meta);
				setLoading(false);
			});
	};

	const handleRowClick = (residentId) => {
		navigate(`/pmo/residents/${residentId}${window.location.search}`);
	};

	useEffect(() => {
		document.title = "Azure | Residents";
		getData();
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			var newSearch = { ...searchParams };

			searchFields.map((field) => {
				if (field.value !== "") {
					newSearch[field.key] = field.value;
				} else {
					delete newSearch[field.key];
				}
			});
			setSearchParams(newSearch);
		}, 500);
		return () => clearTimeout(timer);
	}, [searchName, searchType, searchUnit, searchCode]);

	useEffect(() => {
		getData();
	}, [searchParams]);

	return (
		<>
			<div className="page-header flex-row-left">
				<span className="back-label default-text">Residents</span>
			</div>
			<Section className="content">
				<div className="content-header mb-2 flex-row-right">
					<Pagination
						meta={meta}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						searchFields={searchFields}
						className="pull-right flex-row-left"
					/>
					<Refresh
						className="refresh-btn blue-text ms-2 me-1 pointer"
						sx={{ fontSize: 28 }}
						onClick={getData}
					/>
					<Button
						variant="primary"
						className="booking-action-btn"
						onClick={() => navigate("/pmo/residents/create")}
					>
						Add
					</Button>
				</div>
				<Table className="content-table white-bg">
					<thead className="blue-text">
						<tr>
							<th className="col-3">Resident Code</th>
							<th className="col-3">Name</th>
							<th className="col-3">Type</th>
							<th className="col-3">Units</th>
						</tr>
						<tr className="search-tr">
							<th className="col-3">
								<Form.Control
									type="text"
									value={searchCode}
									onChange={(e) => setSearchCode(e.target.value)}
								/>
							</th>
							<th className="col-3">
								<Form.Control
									type="text"
									value={searchName}
									onChange={(e) => setSearchName(e.target.value)}
								/>
							</th>
							<th className="col-3">
								<Form.Control
									type="text"
									value={searchType}
									onChange={(e) => setSearchType(e.target.value)}
								/>
							</th>
							<th className="col-3">
								<Form.Control
									type="text"
									value={searchUnit}
									onChange={(e) => setSearchUnit(e.target.value)}
								/>
							</th>
						</tr>
					</thead>
					{loading ? (
						<Loader className="content-loader white-bg" />
					) : residents && residents.length != 0 ? (
						<tbody>
							{residents.map((resident) => (
								<tr
									key={resident.id}
									onClick={() => handleRowClick(resident.id)}
								>
									<td className="col-3">{resident.resident_code}</td>
									<td className="col-3">{resident.name}</td>
									<td className="col-3">{resident.resident_type}</td>
									<td className="col-3">
										{resident.resident_units.map((residentUnit) => (
											<div className="resident-unit white-text dark-bg">
												{residentUnit.unit.tower} - {residentUnit.unit.name}
											</div>
										))}
									</td>
								</tr>
							))}
						</tbody>
					) : (
						<div className="empty-content white-bg flex-column">
							<span>No records found.</span>
						</div>
					)}
				</Table>
			</Section>
		</>
	);
};

export default Residents;
