import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { SpaContext } from "../context/SpaContext";
import { useSnackbar } from "react-simple-snackbar";
import { format } from "date-fns";

import Section from "../components/Section";
import Button from "react-bootstrap/esm/Button";
import { SearchOutlined, Add } from "@mui/icons-material";
import Loader from "../components/Loader";

const SpaBookings = () => {
	const navigate = useNavigate();
	const [spaUser] = useContext(SpaContext);
	const [bookings, fetchBookings] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searching, setSearching] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [name, setName] = useState(searchParams.get("q") || "");

	const statusClasses = {
		Pending: "pending",
		"For Revision": "pending",
		Confirmed: "confirmed",
		"Checked In": "checked-in",
		"Partially Checked In": "checked-in",
		"Checked Out": "checked-out",
		"Partially Checked Out": "checked-out",
		"Due for Check Out": "due",
		Expired: "expired",
		Rejected: "rejected",
		Cancelled: "cancelled",
	};

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": spaUser.auth_token,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/spas/bookings${window.location.search}`,
			requestOptions
		);

		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
			navigate("/spa/bookings");
			setLoading(false);
		} else {
			fetchBookings(data.data);
			fetchMeta(data.meta);
			setLoading(false);
			setSearching(false);
		}
	};

	useEffect(() => {
		document.title = "Azure | Bookings";
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (name) {
				setSearchParams({ q: name });
			} else {
				setSearchParams({});
			}
		}, 500);
		return () => clearTimeout(timer);
	}, [name]);

	useEffect(() => {
		getData();
	}, [searchParams]);

	return (
		<>
			<Button
				className="add-btn flex-row-center"
				onClick={() => {
					navigate(`/spa/bookings/create${window.location.search}`);
				}}
			>
				<Add sx={{ fontSize: 20 }} />
				<span className="add-btn-label">New Booking</span>
			</Button>
			<Section className="tenant-content">
				<div className="tenant-search-container flex-row-left">
					<SearchOutlined className="search-icon" />
					<input
						type="text"
						className="form-control search-text tenant-search-text"
						value={name}
						onChange={(e) => setName(e.target.value)}
						placeholder="Search bookings"
					/>
				</div>
				{loading ? (
					<Loader className="booking-form-loader" />
				) : bookings.length != 0 ? (
					bookings.map((booking) => (
						<Link
							to={`/spa/bookings/${booking.id}${window.location.search}`}
							key={booking.id}
						>
							<div to="/test" className="tenent-booking white-bg">
								<span className="tenant-booking-name default-text bold-text block">
									{booking.primary_guest_name}
								</span>
								<span className="tenant-booking-info default-text block">
									{booking.ref_no}
								</span>
								<span className="tenant-booking-info default-text">
									{booking.unit.tower} {booking.unit.name} |{" "}
									{format(new Date(booking.arrival), "MMM dd")} -{" "}
									{format(new Date(booking.departure), "MMM dd")}
								</span>
								<div className="tenant-booking-status-container flex-row-left">
									<div
										className={`tenant-booking-status ${
											statusClasses[booking.display_status]
										}`}
									></div>
									<span className="tenant-booking-info default-text">
										{booking.display_status}
									</span>
								</div>
							</div>
						</Link>
					))
				) : (
					<div className="empty-container flex-column">
						<span>No records found.</span>
					</div>
				)}
			</Section>
		</>
	);
};

export default SpaBookings;
