import { useContext } from "react";
import { Outlet, Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { useSnackbar } from "react-simple-snackbar";
import Navigation from "../components/Navigation";

const Layout = ({ access }) => {
	const [user, setUser] = useContext(UserContext);

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const logout = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/users/logout`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setUser(null);
			successSnackbar(data.message);
		}
	};

	return (
		<>
			<Navigation access={access} />
			<Outlet />
		</>
	);
};

export default Layout;
