import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import Section from "../components/Section";
import Container from "../components/Container";
import Row from "../components/Row";
import Column from "../components/Column";
import { useSnackbar } from "react-simple-snackbar";
import format from "date-fns/format";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/esm/Button";

const Verifier = () => {
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [booking, setBooking] = useState([]);
	const [bookingNo, setBookingNo] = useState("");
	const [pay, setPay] = useState(0);
	const [free, setFree] = useState(0);
	const [available, setAvailable] = useState(0);
	const [showImage, setShowImage] = useState(null);
	const [valid, setValid] = useState(false);
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [parkingVehicle, setParkingVehicle] = useState("");
	const [showCheckoutModal, setShowCheckoutModal] = useState(false);
	const [showVerifyModal, setShowVerifyModal] = useState(false);
	const [guestToVerify, setGuestToVerify] = useState(null);
	const [actionLoading, setActionLoading] = useState(false);

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const getData = () => {
		fetch(`${process.env.REACT_APP_SERVER_URI}/bookings/vehicles/stats`)
			.then((res) => res.json())
			.then((res) => {
				setPay(res.pay);
				setFree(res.free);
				setAvailable(res.available_slots);
			});
	};

	const handleParkingModal = (vehicle_id) => {
		setParkingVehicle(vehicle_id);
		setShow(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				ref_no: bookingNo,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/vehicles/validate`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			if (data.is_valid) {
				setValid(true);
				successSnackbar(data.msg);
			} else {
				dangerSnackbar(data.msg);
				setValid(false);
			}
			setBooking(data.booking);
		}
		setLoading(false);
		setBookingNo("");
	};

	const checkout = async () => {
		setActionLoading(true);

		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				booking_id: booking.id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/checkout`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setBooking(data.booking);
			setShowCheckoutModal(false);
			successSnackbar(data.msg);
		}
		setActionLoading(false);
	};

	const verify = async () => {
		setActionLoading(true);

		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				guest_id: guestToVerify,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/guests/verify`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setBooking(data.booking);
			setShowVerifyModal(false);
			successSnackbar(data.msg);
		}
		setActionLoading(false);
	};

	const changeStatus = async (status, vehicle_id) => {
		setLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				vehicle_id: vehicle_id,
				status: status,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/vehicles/change_status`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			successSnackbar(data.msg);
			setBooking(data.booking);
			setPay(data.pay);
			setFree(data.free);
			console.log(data.booking);
		}
		setBookingNo("");
		setLoading(false);
	};

	const getParking = async (vehicle_id) => {
		setLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				vehicle_id: vehicle_id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/vehicles/get_parking`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			successSnackbar(data.msg);
			setBooking(data.booking);
			setPay(data.pay);
			setFree(data.free);
			setAvailable(data.available_slots);
		}
		setBookingNo("");
		setShow(false);
		setLoading(false);
	};

	useEffect(() => {
		document.title = "Azure | Vehicle Scanner";
		getData();
	}, []);

	const handleVerifyModal = (guest_id) => {
		setGuestToVerify(guest_id);
		setShowVerifyModal(true);
	};

	return (
		<>
			<Section className="full-page dark-bg flex-column">
				<Container className="verifier-content">
					<div className="scanner-body white-bg">
						<form
							className="scanner-form flex-column p-5"
							onSubmit={handleSubmit}
						>
							<input
								type="text"
								className="form-control align-center"
								placeholder="Reference No."
								value={bookingNo}
								autoFocus
								onChange={(e) => setBookingNo(e.target.value)}
							/>
						</form>
						<Container className="scanner-info ps-5 pe-5">
							{loading ? (
								<div className="scanner-loading flex-column">Loading</div>
							) : booking.length != 0 ? (
								<>
									<Row>
										<Column className="info-col align-center">
											<span
												className={`validity-text ${
													valid ? "blue-text" : "danger-text"
												}`}
											>
												{valid ? "VALID" : "INVALID"}
											</span>
										</Column>
									</Row>
									<Row>
										<Column className="info-col">
											<label className="form-label">Unit</label>
											<span className="info-text">
												{booking.unit.tower} {booking.unit.name}
											</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Check In</label>
											<span className="info-text block mb-2">
												{format(
													new Date(booking.arrival),
													"MMM dd, yyyy (hh:mm a)"
												)}
											</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Check Out</label>
											<span className="info-text block mb-2">
												{format(
													new Date(booking.departure),
													"MMM dd, yyyy (hh:mm a)"
												)}
											</span>
										</Column>
									</Row>
									{valid && (
										<>
											<Row className="mt-5 mb-3">
												<Column className="p-0">
													<span className="guests-header">Guests</span>
												</Column>
											</Row>
											{booking.guests &&
												booking.guests
													.sort((a, b) => a.name.localeCompare(b.name))
													.map((guest) => (
														<div className="guest-row mb-5">
															<Row
																key={guest.id}
																className="header-row flex-row-left"
															>
																<Column>
																	<span className="white-text">
																		{guest.name}
																	</span>
																</Column>
																<Column className="flex-row-right">
																	{guest.verified ? (
																		<span className="verifier-verified-lbl flex-column">
																			Verified
																		</span>
																	) : (
																		<Button
																			variant="light"
																			className="text-sm booking-action-btn bold-text"
																			onClick={() =>
																				handleVerifyModal(guest.id)
																			}
																		>
																			Verify
																		</Button>
																	)}
																</Column>
															</Row>

															<Row>
																<Column className="info-col">
																	<label className="form-label">Age</label>
																	<span className="info-text">{guest.age}</span>
																</Column>
																<Column className="info-col">
																	<label className="form-label">
																		Below 3 Feet
																	</label>
																	<span className="info-text">
																		{guest.below_min_height ? "Yest" : "No"}
																	</span>
																</Column>
																<Column className="info-col">
																	<label className="form-label">Vehicle</label>
																	<span className="info-text">
																		{guest.vehicle.length !== 0
																			? `${guest.vehicle[0].model} (${guest.vehicle[0].type}) - ${guest.vehicle[0].plate_no}`
																			: "N/A"}
																	</span>
																</Column>
																<Column className="info-col">
																	<label className="form-label">Parking</label>
																	<span className="info-text">
																		{guest.vehicle.length !== 0 &&
																		guest.vehicle[0].with_parking
																			? `${guest.vehicle[0].parking_tower} - ${
																					guest.vehicle[0].parking_level &&
																					guest.vehicle[0].parking_level + ", "
																			  } ${guest.vehicle[0].parking_slot}`
																			: "N/A"}
																	</span>
																</Column>
															</Row>
															<Row>
																<Column className="info-col">
																	<label className="form-label">Valid ID</label>
																	{guest.id_img ? (
																		<div
																			className="booking-img"
																			style={{
																				background: `url(${guest.id_img})`,
																			}}
																			onClick={() => setShowImage(guest.id_img)}
																		/>
																	) : (
																		"N/A"
																	)}
																</Column>
																<Column className="info-col">
																	<label className="form-label">
																		Vax Cert.
																	</label>
																	{guest.vax_img ? (
																		<div
																			className="booking-img"
																			style={{
																				background: `url(${guest.vax_img})`,
																			}}
																			onClick={() =>
																				setShowImage(guest.vax_img)
																			}
																		/>
																	) : (
																		"N/A"
																	)}
																</Column>
															</Row>
															<Row>
																<Column className="flex-row-right"></Column>
															</Row>
														</div>
													))}
										</>
									)}
								</>
							) : (
								""
							)}
							{/* {booking.self_checkout && booking.status === "Checked In" && (
								<div className="flex-row-center full mt-5 mb-5">
									<Button
										type="button"
										variant="warning"
										className="booking-action-btn"
										onClick={() => setShowCheckoutModal(true)}
									>
										Checkout
									</Button>
								</div>
							)} */}
						</Container>
					</div>
				</Container>
			</Section>
			<div className="scanner-logout-container flex-row-center full">
				<Button
					variant="light"
					className="booking-action-btn"
					onClick={() => navigate("../pmo/logout")}
				>
					Log Out
				</Button>
			</div>
			<Modal show={show} onHide={handleClose}>
				<form onSubmit={handleSubmit}>
					<Modal.Header closeButton>
						<Modal.Title>Get Pay Parking?</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>Would you like to get a pay parking slot for this vehicle?</p>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="parking-modal-btn me-3"
							onClick={handleClose}
						>
							Close
						</Button>
						<Button
							variant="primary"
							className="parking-modal-btn"
							onClick={() => getParking(parkingVehicle)}
						>
							Get Parking
						</Button>
					</Modal.Footer>
				</form>
			</Modal>
			<Modal
				show={showCheckoutModal}
				onHide={() => setShowCheckoutModal(false)}
			>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Checkout
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">
						Are you sure you want to check this guest out?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setShowCheckoutModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="warning"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={checkout}
					>
						{actionLoading ? "Loading" : "Check Out"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showVerifyModal} onHide={() => setShowVerifyModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Verify
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">Are you sure you want to verify this guest?</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setShowVerifyModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={verify}
					>
						{actionLoading ? "Loading" : "Verify"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showImage}
				size="xl"
				className="img-modal"
				onHide={() => setShowImage(null)}
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body className="p-0 flex-column">
					<img src={showImage} className="img-open" />
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Verifier;
