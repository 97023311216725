import { useEffect, useState, useContext } from "react";
import { TenantContext } from "../context/TenantContext";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import coloredLogo from "../assets/logo_colored.png";

const Password = () => {
	const navigate = useNavigate();
	const [tenantUser, setTenantUser] = useContext(TenantContext);
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [passwordMessage, setPasswordMessage] = useState("");
	const [passwordConfirmError, setPasswordConfirmError] = useState(false);
	const [passwordLoading, setPasswordLoading] = useState(false);
	const [ready, setReady] = useState(false);

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const changePassword = async (e) => {
		e.preventDefault();
		setLoading(true);
		const requestOptions = {
			method: "POST",
			body: JSON.stringify({
				password: password,
				token: token,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/password/reset`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			setTenantUser(null);
			successSnackbar(data.msg);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (password && passwordConfirm && password === passwordConfirm) {
			setReady(true);
			setPasswordConfirmError(false);
		} else {
			setReady(false);
			if (passwordConfirm && passwordConfirm !== password) {
				setPasswordConfirmError(true);
			} else {
				setPasswordConfirmError(false);
			}
		}
	}, [password, passwordConfirm]);

	useEffect(() => {
		if (!tenantUser) {
			navigate("/login");
		}
	}, [tenantUser]);

	return (
		<div className="page-section full-page dark-bg flex-column">
			<Container>
				<div className="reset-password-container col-12 p-30 bg-white rounded flex-column">
					<img src={coloredLogo} className="password-logo" />
					<span className="reset-password-label">Reset Password</span>
					<Form onSubmit={changePassword} className="full">
						<Row className="form-block-sm">
							<Col>
								<Form.Label>New Password</Form.Label>
								<Form.Control
									type="password"
									className={`form-input text-sm ${
										passwordMessage && !passwordMessage.is_valid && "error"
									}`}
									placeholder="Enter new password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<div className="login-error-msg full align-left">
									{passwordLoading ? (
										<div className="ri-loader-3-line rotating fit mt-1" />
									) : passwordMessage ? (
										<div className="full align-left mt-1">
											<span
												className={`label-md opacity-7 ${
													passwordMessage.is_valid
														? "text-success"
														: "text-danger"
												}`}
											>
												{passwordMessage.msg}
											</span>
										</div>
									) : (
										""
									)}
								</div>
							</Col>
						</Row>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Confirm Password</Form.Label>
								<Form.Control
									type="password"
									className={`form-input text-sm ${
										passwordConfirmError && "error"
									}`}
									placeholder="Enter new password again"
									value={passwordConfirm}
									onChange={(e) => setPasswordConfirm(e.target.value)}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button
									type="submit"
									className="btn-lg text-md full text-medium-inter"
									disabled={loading || !ready}
								>
									{loading ? "Saving.." : "Change Password"}
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Container>
		</div>
	);
};

export default Password;
