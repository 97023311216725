import React, { createContext, useEffect, useState } from "react";

export const SpaContext = createContext();

export const SpaProvider = (props) => {
	const [spaUser, setSpaUser] = useState(
		JSON.parse(localStorage.getItem("condopalSpaUser")) || null
	);

	const fetchUser = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": spaUser ? spaUser.auth_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/spas/s/me`,
			requestOptions
		);

		if (!response.ok) {
			setSpaUser(null);
		}
	};

	useEffect(() => {
		if (spaUser) {
			fetchUser();
		}
		localStorage.setItem("condopalSpaUser", JSON.stringify(spaUser));
	}, [spaUser]);

	return (
		<SpaContext.Provider value={[spaUser, setSpaUser]}>
			{props.children}
		</SpaContext.Provider>
	);
};
