import { useEffect, useContext } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { TenantContext } from "../context/TenantContext";

const TenantLogout = () => {
	const [tenantUser, setTenantUser] = useContext(TenantContext);

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const logout = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": tenantUser.auth_token,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/logout${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setTenantUser(null);
		}
	};

	useEffect(() => {
		logout();
	}, []);
	return "";
};

export default TenantLogout;
