import { useState, useContext, useEffect } from "react";
import { SpaContext } from "../context/SpaContext";
import { Link } from "react-router-dom";
import coloredLogo from "../assets/logo_colored.png";
import {
	NotificationsOutlined,
	ListAltOutlined,
	LogoutOutlined,
} from "@mui/icons-material";
import { useSnackbar } from "react-simple-snackbar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/Modal";

const SpaNavigation = () => {
	const [spaUser] = useContext(SpaContext);
	const [activeNav, setActiveNav] = useState("bookings");
	const [openSidebar, setOpenSidebar] = useState(false);
	const [showResetPassword, setShowResetPassword] = useState(false);
	const [newPassword, setNewPassword] = useState("");
	const [passwordLoading, setPasswordLoading] = useState(false);
	const [showPasswordModal, setShowPasswordModal] = useState(false);

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getAccess = () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": spaUser ? spaUser.auth_token : "",
			},
		};
		fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/spas/s/me/access`,
			requestOptions
		)
			.then((res) => res.json())
			.then((res) => {
				setShowPasswordModal(res.access.temp_pw);
			});
	};

	const changePassword = async (e) => {
		e.preventDefault();
		setPasswordLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": spaUser ? spaUser.auth_token : "",
			},
			body: JSON.stringify({
				password: newPassword,
				temporary: false,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/spas/s/${spaUser.spa_id}/password`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			successSnackbar(data.msg);
			setShowPasswordModal(false);
		}
		setPasswordLoading(false);
	};

	useEffect(() => {
		getAccess();
	}, []);

	const navControl = (page) => {
		setActiveNav(page);
		setOpenSidebar(false);
	};

	return (
		<>
			<div className={`tenant-sidebar ${openSidebar ? "active" : ""}`}>
				<div className="tenant-sidebar-header flex-column">
					<img src={coloredLogo} className="tenant-sidebar-logo" />
					<span className="bold-text sidebar-user mt-4 align-center ps-2 pe-2">
						{spaUser && spaUser.name}
					</span>
				</div>
				<div className="sidebar-body">
					<Link
						to={`/spa/bookings/${window.location.search}`}
						className={`tenant-sidebar-nav-item ${
							activeNav == "bookings" ? "active" : ""
						}`}
						onClick={() => navControl("bookings")}
					>
						<ListAltOutlined className="nav-icon me-3" sx={{ fontSize: 22 }} />
						Bookings
					</Link>
					<hr />
					<Link
						to={`/spa/logout${window.location.search}`}
						id="logoutBtn"
						className="tenant-sidebar-nav-item danger-text"
					>
						<LogoutOutlined className="nav-icon me-3" sx={{ fontSize: 22 }} />
						Logout
					</Link>
				</div>
			</div>
			<div
				className={`nav-overlay ${openSidebar ? "" : "hidden"}`}
				onClick={() => setOpenSidebar((sidebarStatus) => !sidebarStatus)}
			></div>
			<div className="tenant-header dark-bg flex-row-center">
				<div
					className="nav-toggle flex-row-left"
					onClick={() => setOpenSidebar((sidebarStatus) => !sidebarStatus)}
				>
					<div className="nav-toggle-bar"></div>
				</div>
				<span className="page-title white-text">Bookings</span>
				{/* <NotificationsOutlined
					className="notif-icon white-text"
					sx={{ fontSize: 28 }}
				/> */}
			</div>
			<Modal
				centered
				show={showPasswordModal}
				onHide={() => setShowPasswordModal(false)}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Change Password
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={changePassword}>
					<Modal.Body className="pt-4 pb-4">
						<Form.Group>
							<div
								className="show-password-btn flex-row-right"
								onClick={() => setShowResetPassword(!showResetPassword)}
							>
								<span>{showResetPassword ? "Hide" : "Show"}</span>
							</div>
							<Form.Control
								required
								type={showResetPassword ? "text" : "password"}
								className="text-sm password-text"
								size="lg"
								placeholder="New Password"
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							type="submit"
							variant="primary"
							className="modal-btn bold-text text-sm"
							disabled={passwordLoading}
						>
							{passwordLoading ? "Loading" : "Change"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default SpaNavigation;
