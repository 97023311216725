import { useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { UserContext } from "../context/UserContext";
import Section from "../components/Section";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import Guest from "../components/Guest";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/esm/Button";
import { Refresh } from "@mui/icons-material";

const Guests = () => {
	const [user] = useContext(UserContext);
	const [guests, fetchGuests] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [loading, setLoading] = useState(true);
	const [actionLoading, setActionLoading] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [searchPass, setSearchPass] = useState(searchParams.get("pass") || "");
	const [searchName, setSearchName] = useState(searchParams.get("name") || "");
	const [searchUnit, setSearchUnit] = useState(searchParams.get("unit") || "");
	const [guestToEdit, setGuestToEdit] = useState(null);
	const [searchTower, setSearchTower] = useState(
		searchParams.get("tower") || ""
	);

	const searchFields = [
		{ key: "pass", value: searchPass },
		{ key: "name", value: searchName },
		{ key: "tower", value: searchTower },
		{ key: "unit", value: searchUnit },
	];

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = () => {
		setLoading(true);
		fetch(
			`${process.env.REACT_APP_SERVER_URI}/guests/${window.location.search}`
		)
			.then((res) => res.json())
			.then((res) => {
				fetchGuests(res.data);
				fetchMeta(res.meta);
				setLoading(false);
			});
	};

	const checkoutGuest = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				guest_id: guestToEdit.Guest.id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/guests/checkout`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			setGuestToEdit(null);
			getData();
			successSnackbar(data.msg);
		}
		setActionLoading(false);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			var newSearch = { ...searchParams };

			searchFields.map((field) => {
				if (field.value !== "") {
					newSearch[field.key] = field.value;
				} else {
					delete newSearch[field.key];
				}
			});
			setSearchParams(newSearch);
		}, 500);
		return () => clearTimeout(timer);
	}, [searchPass, searchName, searchUnit, searchTower]);

	useEffect(() => {
		document.title = "Azure | Guests";
		getData();
	}, [searchParams]);

	useEffect(() => {
		console.log(guestToEdit);
	}, [guestToEdit]);

	return (
		<>
			<div className="page-header flex-row-left">
				<span className="back-label default-text">Guests</span>
			</div>
			<Section className="content">
				<div className="content-header flex-row-right">
					<Pagination
						meta={meta}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						searchFields={searchFields}
						className="pull-right flex-row-left"
					/>
					<Refresh
						className="refresh-btn blue-text ms-2 pointer"
						sx={{ fontSize: 28 }}
						onClick={getData}
					/>
				</div>
				<Table className="content-table white-bg">
					<thead className="blue-text">
						<tr>
							<th>Visitor's Pass</th>
							<th>Name</th>
							<th>Tower</th>
							<th>Unit</th>
							<th className="align-center">Action</th>
						</tr>
						<tr className="search-tr">
							<th>
								<Form.Control
									type="text"
									value={searchPass}
									onChange={(e) => setSearchPass(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchName}
									onChange={(e) => setSearchName(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchTower}
									onChange={(e) => setSearchTower(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchUnit}
									onChange={(e) => setSearchUnit(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control type="text" className="invisible" disabled />
							</th>
						</tr>
					</thead>
					{loading ? (
						<Loader className="content-loader white-bg" />
					) : guests && guests.length != 0 ? (
						<tbody>
							{guests.map((guest) => (
								<Guest
									key={guest.Guest.id}
									guest={guest}
									setGuestToEdit={setGuestToEdit}
								/>
							))}
						</tbody>
					) : (
						<div className="empty-content white-bg flex-column">
							<span>No records found.</span>
						</div>
					)}
				</Table>
			</Section>
			<Modal show={guestToEdit} onHide={() => setGuestToEdit(null)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Checkout
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">
						Are you sure you want to proceed with this check out?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setGuestToEdit(null)}
					>
						Cancel
					</Button>
					<Button
						variant="warning"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={checkoutGuest}
					>
						{actionLoading ? "Loading" : "Checkout"}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Guests;
