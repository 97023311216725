import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { TenantContext } from "../context/TenantContext";
import { useSnackbar } from "react-simple-snackbar";
import { format } from "date-fns";

import Section from "../components/Section";
import Button from "react-bootstrap/esm/Button";
import { SearchOutlined, Add } from "@mui/icons-material";
import Loader from "../components/Loader";

const TenantAnnouncements = () => {
	const navigate = useNavigate();
	const [tenantUser] = useContext(TenantContext);
	const [announcements, fetchAnnouncements] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searching, setSearching] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [name, setName] = useState(searchParams.get("q") || "");

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": tenantUser.auth_token,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/announcements/${window.location.search}`,
			requestOptions
		);

		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
			navigate("/announcements");
			setLoading(false);
		} else {
			fetchAnnouncements(data.data);
			fetchMeta(data.meta);
			setLoading(false);
			setSearching(false);
		}
	};

	useEffect(() => {
		document.title = "Azure | Announcements";
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (name) {
				setSearchParams({ q: name });
			} else {
				setSearchParams({});
			}
		}, 500);
		return () => clearTimeout(timer);
	}, [name]);

	useEffect(() => {
		getData();
	}, [searchParams]);

	return (
		<>
			<Section className="tenant-content">
				<div className="tenant-search-container flex-row-left">
					<SearchOutlined className="search-icon" />
					<input
						type="text"
						className="form-control search-text tenant-search-text"
						value={name}
						onChange={(e) => setName(e.target.value)}
						placeholder="Search announcements"
					/>
				</div>
				{loading ? (
					<Loader className="booking-form-loader" />
				) : announcements.length != 0 ? (
					announcements.map((announcement) => (
						<Link
							to={`/announcements/${announcement.id}${window.location.search}`}
							key={announcement.id}
						>
							<div className="tenant-announcement white-bg">
								{/* <div className="tenant-announcement-cover"></div> */}
								<span className="tenant-announcement-title bold-text">
									{announcement.title}
								</span>
								<p className="tenant-announcement-content label-md">
									{announcement.content}
								</p>
								<span className="label-md">
									{format(new Date(announcement.created_at), "MMM dd, yyyy")}
								</span>
							</div>
						</Link>
					))
				) : (
					<div className="empty-container flex-column">
						<span>No announcements.</span>
					</div>
				)}
			</Section>
		</>
	);
};

export default TenantAnnouncements;
