import coloredLogo from "../assets/logo_colored.png";
import Container from "react-bootstrap/Container";
import Done from "@mui/icons-material/Done";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";

const ParkingSuccess = () => {
	return (
		<Container className="flex-column parking-success-container gx-5">
			<img src={coloredLogo} alt="logo" className="parking-success-logo" />
			<CheckCircleOutline
				className="parking-success-icon text-success mb-15"
				sx={{ fontSize: 48 }}
			/>
			<h1 className="text-xl">Thank you!</h1>
			<p className="align-center opacity-7">
				Please proceed to the parking booth and present your valid ID.
			</p>
		</Container>
	);
};

export default ParkingSuccess;
