import React, { createContext, useEffect, useState } from "react";

export const TenantContext = createContext();

export const TenantProvider = (props) => {
	const [tenantUser, setTenantUser] = useState(
		JSON.parse(localStorage.getItem("condopalTenantUser")) || null
	);

	const fetchUser = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": tenantUser ? tenantUser.auth_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/me`,
			requestOptions
		);

		if (!response.ok) {
			setTenantUser(null);
		}
	};

	useEffect(() => {
		if (tenantUser) {
			fetchUser();
		}
		localStorage.setItem("condopalTenantUser", JSON.stringify(tenantUser));
	}, [tenantUser]);

	return (
		<TenantContext.Provider value={[tenantUser, setTenantUser]}>
			{props.children}
		</TenantContext.Provider>
	);
};
