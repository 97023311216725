import React, { createContext, useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import ReportItem from "../components/ReportItem";

export const ReportContext = createContext();

export const ReportProvider = (props) => {
	const [reports, setReports] = useState([]);

	return (
		<ReportContext.Provider value={[reports, setReports]}>
			{props.children}
			{reports.length !== 0 && (
				<div className="report-loader">
					<div className="report-loader-header flex-row-left">
						<span className="report-loader-title white-text bold-text">
							Generating Report{reports.length > 1 ? "s" : ""}
						</span>
						<Close
							sx={{ fontSize: 24 }}
							className="report-loader-close bold-text white-text"
							onClick={() => setReports([])}
						/>
					</div>
					<div className="report-loader-body bg-white">
						{reports.map((report) => (
							<ReportItem report={report} />
						))}
					</div>
				</div>
			)}
		</ReportContext.Provider>
	);
};
