import coloredLogo from "../assets/logo_colored.png";
import Container from "../components/Container";

const Privacy = () => {
	return (
		<div className="terms-content pt-5 pb-5">
			<Container>
				<div className="align-center">
					<img src={coloredLogo} alt="" className="privacy-logo" />
					<h2 className="bold-text text-xl">PRIVACY NOTICE</h2>
				</div>
				<br />
				<p>
					Azure Urban Resort Residences Condominium Corporation (‘AZURE”) is
					committed to protect and respect your personal data privacy. We value
					your privacy by providing appropriate measures in protecting and
					managing your personal data. Our Privacy Policy is consistent with,
					the Data Privacy Act of 2012 (DPA), its Implementing Rules and
					Regulations (IRR), other issuances of National Privacy Commission
					(NPC), as well as other relevant laws of the Philippines.
				</p>
				<br />
				<h2 className="bold-text">Consent</h2>
				<p>
					By providing us your Personal Data, you will be treated as having
					given your permission for our collection, use and processing of your
					Personal Data and you have accepted the policies and practices
					described in this Notice.
				</p>
				<br />
				<h2 className="bold-text">
					Why and what Personal Data does Azure collect?
				</h2>
				<p>
					Azure collects only the Personal Data needed to register your guests
					in our Azure Property Management System (APMS). Personal data
					collected includes any of the following:
				</p>
				<br />
				<ol type="a">
					<li>Name</li>
					<li>Unit Number and Tower</li>
					<li>Nationality</li>
					<li>Age</li>
					<li>Email Address</li>
					<li>Address</li>
					<li>Contact Number</li>
					<li>Valid ID</li>
					<li>Vaccination ID/Card/Certificate (optional)</li>
					<li>Car details (if applicable)</li>
					<li>Parking details (if applicable)</li>
				</ol>
				<br />
				<h2 className="bold-text">
					How does Azure collection your Personal Data?
				</h2>
				<p>
					We process the Personal Data you knowingly and voluntarily provide
					when you use APMS. The data you provide will then be used to provide
					the services you requested.
				</p>
				<br />
				<h2 className="bold-text">
					Where does Azure store your Personal Data?
				</h2>
				<p>
					We store your personal information in our database using Amazon Web
					Services. In addition, we utilize firewall protection as an additional
					layer of security. We use anti-virus and anti-malware software and
					regularly update our virus protection. Third parties who we hire to
					provide services and who have access to our user’s data are required
					to implement privacy and security practices that we deem adequate.
				</p>
				<br />
				<h2 className="bold-text">
					How long does Azure retain your Personal Data?
				</h2>
				<p>
					AZURE will retain your Personal Data once it serves its purpose unless
					a longer period is required by law.
				</p>
				<br />
				<h2 className="bold-text">
					How does Azure take care of your Personal Data?
				</h2>
				<p>
					Azure takes reasonable steps to protect it from misuse, loss or
					unauthorize access, modification, and unauthorized disclosure by
					establishing and enforcing: (a) confidentiality requirements and data
					privacy awareness to our staff; (b) file/document storage security
					policies; and (c) limitation on access to personal data.
				</p>
				<br />
				<h2 className="bold-text">To whom are your Personal Data disclosed?</h2>
				<p>
					Azure will ensure to maintain the confidentiality of data subject's
					information and are not allowed to disclose and share any information
					without your consent. Personal information under the custody of Azure
					shall be disclosed and shared only pursuant to a lawful purpose, and
					to authorized recipients of such data.
				</p>
				<br />
				<h2 className="bold-text">Data Protection Officer</h2>
				<p>
					To ask questions and make comments on this Notice and our privacy
					practices and compliance with applicable laws, you may reach our Data
					Protection Officer (DPO) through phone number: (8) 558-5400 local 152
					and/or email address:{" "}
					<a href="mailto: aurrcc.dpo@outlook.com">aurrcc.dpo@outlook.com</a>
				</p>
			</Container>
		</div>
	);
};

export default Privacy;
