import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const TenantHome = ({ session }) => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate(`/bookings${window.location.search}`);
	}, [session]);

	return "";
};

export default TenantHome;
