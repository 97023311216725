import { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Section from "../components/Section";
import { useSnackbar } from "react-simple-snackbar";
import Row from "../components/Row";
import Column from "../components/Column";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { ArrowBackOutlined, CloseOutlined } from "@mui/icons-material";
import { UserContext } from "../context/UserContext";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/esm/Table";
import Loader from "../components/Loader";

const Tenant = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [loading, setLoading] = useState(true);
	const [actionLoading, setActionLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [passwordLoading, setPasswordLoading] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showPasswordModal, setShowPasswordModal] = useState(false);
	const [showResetPassword, setShowResetPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [tenant, setTenant] = useState(null);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [msisdn, setMsisdn] = useState("");
	const [units, setUnits] = useState([]);
	const [parkings, setParkings] = useState([]);
	const [spas, setSpas] = useState([]);
	const [password, setPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [showUnitModal, setShowUnitModal] = useState(false);
	const [showParkingModal, setShowParkingModal] = useState(false);
	const [showSpaModal, setShowSpaModal] = useState(false);

	const [unitToDelete, setUnitToDelete] = useState(null);
	const [parkingToDelete, setParkingToDelete] = useState(null);
	const [spaToDelete, setSpaToDelete] = useState(null);

	const [unitTower, setUnitTower] = useState("");
	const [unitNo, setUnitNo] = useState("");
	const [unitType, setUnitType] = useState("");
	const [unitGuests, setUnitGuests] = useState("");

	const [parkingTower, setParkingTower] = useState("");
	const [parkingLevel, setParkingLevel] = useState("");
	const [parkingSlot, setParkingSlot] = useState("");

	const [unitsToDelete, setUnitsToDelete] = useState([]);
	const [parkingsToDelete, setParkingsToDelete] = useState([]);
	const [spasToDelete, setSpasToDelete] = useState([]);

	const [spaQuery, setSpaQuery] = useState("");
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [fromSuggestions, setFromSuggestions] = useState(false);
	const [loadingSuggestions, setLoadingSuggestions] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [items, setItems] = useState([]);

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getTenant = () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
		};
		fetch(`${process.env.REACT_APP_SERVER_URI}/tenants/t/${id}`, requestOptions)
			.then((res) => res.json())
			.then((res) => {
				setTenant(res.tenant);
				const existingSpas = res.spas?.map((spa, index) => ({
					new: false,
					index: index + 1,
					id: spa.id,
					name: spa.name,
					email: spa.email,
				}));
				setSpas(existingSpas || []);
				setLoading(false);
			});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		setSaveLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				name: name,
				email: email,
				msisdn: msisdn,
				password: password,
				units: units?.filter((unit) => unit.id === null) || [],
				parking: parkings?.filter((parking) => parking.id === null) || [],
				spas: spas?.filter((spa) => spa.new === true) || [],
				delete_units: unitsToDelete,
				delete_parking: parkingsToDelete,
				delete_spas: spasToDelete,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/t/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			if (data.created) {
				navigate(`/pmo/tenants/${data.tenant.id}${window.location.search}`);
			}
			setTenant(data.tenant);
			setUnitsToDelete([]);
			setParkingsToDelete([]);
			setSpasToDelete([]);
			successSnackbar(data.msg);
		}
		setActionLoading(false);
		setSaveLoading(false);
	};

	const changePassword = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		setPasswordLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				password: newPassword,
				temporary: true,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/t/${id}/password`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			successSnackbar(data.msg);
			setShowPasswordModal(false);
			setNewPassword("");
		}
		setActionLoading(false);
		setPasswordLoading(false);
	};

	const deleteTenant = async () => {
		setActionLoading(true);
		setDeleteLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
		};
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/t/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			navigate(`/pmo/tenants${window.location.search}`);
			successSnackbar(data.msg);
		}
		setShowDeleteModal(false);
		setActionLoading(false);
		setDeleteLoading(false);
	};

	const addUnit = (e) => {
		e.preventDefault();
		setUnits((prevUnits) => [
			...prevUnits,
			{
				id: null,
				index: units.length + 1,
				tower: unitTower,
				name: unitNo,
				type: unitType,
				max_guests: unitGuests,
			},
		]);
		setUnitTower("");
		setUnitNo("");
		setUnitType("");
		setUnitGuests("");
		setShowUnitModal(false);
	};

	const addParking = (e) => {
		e.preventDefault();
		setParkings((prevParkings) => [
			...prevParkings,
			{
				id: null,
				index: parkings.length + 1,
				tower: parkingTower,
				level: parkingLevel,
				slot: parkingSlot,
			},
		]);
		setParkingTower("");
		setParkingLevel("");
		setParkingSlot("");
		setShowParkingModal(false);
	};

	const deleteUnit = () => {
		setActionLoading(true);
		if (unitToDelete.id) {
			setUnitsToDelete((prevUnits) => [...prevUnits, unitToDelete.id]);
		}
		setUnits(units.filter((unit) => unit.index !== unitToDelete.index));
		setActionLoading(false);
		setUnitToDelete(null);
	};

	const deleteParking = () => {
		setActionLoading(true);
		if (parkingToDelete.id) {
			setParkingsToDelete((prevParkings) => [
				...prevParkings,
				parkingToDelete.id,
			]);
		}
		setParkings(
			parkings.filter((parking) => parking.index !== parkingToDelete.index)
		);
		setActionLoading(false);
		setParkingToDelete(null);
	};

	const deleteSpa = () => {
		setActionLoading(true);
		if (spaToDelete.id) {
			setSpasToDelete((prevSpas) => [...prevSpas, spaToDelete.id]);
		}
		setSpas(spas.filter((spa) => spa.index !== spaToDelete.index));
		setActionLoading(false);
		setSpaToDelete(null);
	};

	const getSuggestions = async () => {
		setLoadingSuggestions(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/spa_search?q=${spaQuery}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong");
			setShowSuggestions(false);
		} else {
			setSelectedItem(null);
			setItems(data.results);
		}
		setLoadingSuggestions(false);
	};

	const handleSuggestionHover = () => {
		setFromSuggestions(true);
		setSelectedItem(null);
	};

	const highlightSuggestion = (e) => {
		if (e.keyCode === 40) {
			e.preventDefault();
			setFromSuggestions(true);
			if (items.length !== 0) {
				if (selectedItem !== null && selectedItem < items.length - 1) {
					setSelectedItem((prevSelectedItem) => prevSelectedItem + 1);
				} else {
					setSelectedItem(0);
				}
			} else {
				setSelectedItem(null);
			}
		} else if (e.keyCode === 38) {
			e.preventDefault();
			setFromSuggestions(true);
			if (items.length !== 0) {
				if (selectedItem !== null && selectedItem !== 0) {
					setSelectedItem((prevSelectedItem) => prevSelectedItem - 1);
				} else {
					setSelectedItem(items.length - 1);
				}
			} else {
				setSelectedItem(null);
			}
		} else {
			setFromSuggestions(false);
		}
	};

	const addSpa = (e, spa) => {
		e.preventDefault();
		if (spa === null) {
			dangerSnackbar("Please select an existing SPA.");
		} else {
			if (spas.filter((spaFilter) => spaFilter.id === spa.id).length === 0) {
				setSpas((prevSpas) => [
					...prevSpas,
					{
						new: true,
						index: spas.length + 1,
						id: spa.id,
						name: spa.name,
						email: spa.email,
					},
				]);
			}
		}
		setSpaQuery("");
		setItems([]);
		setShowSuggestions(false);
		setSelectedItem(null);
		setShowSpaModal(false);
	};

	useEffect(() => {
		if (tenant) {
			setName(tenant.name);
			setEmail(tenant.email);
			setMsisdn(tenant.msisdn);
			const existingUnits = tenant.tenant_units?.map((tenantUnit, index) => ({
				id: tenantUnit.unit.id,
				index: index + 1,
				tower: tenantUnit.unit.tower,
				name: tenantUnit.unit.name,
				type: tenantUnit.unit.type,
				max_guests: tenantUnit.unit.max_guests,
			}));
			const existingParkings = tenant.parkings?.map((parking, index) => ({
				id: parking.id,
				index: index + 1,
				tower: parking.tower,
				level: parking.level,
				slot: parking.slot,
			}));
			setUnits(existingUnits || []);
			setParkings(existingParkings || []);
		}
	}, [tenant]);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			getTenant();
		}
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		if (!fromSuggestions) {
			if (spaQuery !== "") {
				setShowSuggestions(true);
				const timer = setTimeout(() => {
					getSuggestions();
				}, 500);
				return () => clearTimeout(timer);
			} else {
				setShowSuggestions(false);
				setLoadingSuggestions(true);
			}
		}
	}, [spaQuery]);

	useEffect(() => {
		if (selectedItem !== null) {
			setSpaQuery(`${items[selectedItem].name} (${items[selectedItem].email})`);
		}
	}, [selectedItem]);

	return (
		<>
			<div className="page-header flex-row-left">
				<Link
					to={`/pmo/tenants${window.location.search}`}
					className="flex-row-left"
				>
					<ArrowBackOutlined
						className="back-icon blue-text me-2"
						sx={{ fontSize: 28 }}
					/>
					{loading ? (
						""
					) : (
						<span className="back-label default-text">
							{tenant
								? `Unit Owners > ${tenant.name}`
								: "Unit Owners > Create New"}
						</span>
					)}
				</Link>
			</div>
			<Section className="content">
				{loading ? (
					<div className="content-loading flex-column">Loading</div>
				) : (
					<div className="info-container white-bg p-5">
						<Form onSubmit={handleSubmit}>
							<span className="section-title mb-3 block">Unit Owner Info</span>
							<Row className="mb-4">
								<Column>
									<Form.Group>
										<Form.Label>Full Name</Form.Label>
										<Form.Control
											required
											type="text"
											className="text-sm capitalize"
											size="lg"
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
									</Form.Group>
								</Column>
							</Row>
							<Row className="mb-4">
								<Column>
									<Form.Group>
										<Form.Label>Email</Form.Label>
										<Form.Control
											required
											type="email"
											className="text-sm"
											size="lg"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</Form.Group>
								</Column>
								<Column>
									<Form.Group>
										<Form.Label>Mobile Number</Form.Label>
										<Form.Control
											type="number"
											className="text-sm"
											size="lg"
											value={msisdn}
											onChange={(e) => setMsisdn(e.target.value)}
										/>
									</Form.Group>
								</Column>
							</Row>
							{!tenant && (
								<Row className="mb-4">
									<Column>
										<Form.Group>
											<Form.Label>Temporary Password</Form.Label>
											<div>
												<div
													className="show-password-btn flex-row-right"
													onClick={() => setShowPassword(!showPassword)}
												>
													<span>{showPassword ? "Hide" : "Show"}</span>
												</div>
												<Form.Control
													required
													type={showPassword ? "text" : "password"}
													className="text-sm"
													size="lg"
													value={password}
													onChange={(e) => setPassword(e.target.value)}
												/>
											</div>
										</Form.Group>
									</Column>
								</Row>
							)}
							<Row className="mb-4">
								<Column>
									<Form.Label>Units</Form.Label>
									<Table className="tenant-table units">
										<thead>
											<tr className="text-sm">
												<th>Tower</th>
												<th>Unit</th>
												<th>Type</th>
												<th>Max. Guests</th>
												<th className="tenant-action-col"></th>
											</tr>
										</thead>
										<tbody>
											{units?.map((unit) => (
												<tr key={unit.index} className="text-sm">
													<td>{unit.tower}</td>
													<td>{unit.name}</td>
													<td>{unit.type}</td>
													<td>{unit.max_guests}</td>
													<td className="tenant-action-col">
														<a
															href="#"
															className="tenant-action-btn text-danger"
															onClick={(e) => {
																e.preventDefault();
																setUnitToDelete(unit);
															}}
														>
															<CloseOutlined sx={{ fontSize: 20 }} />
														</a>
													</td>
												</tr>
											))}
										</tbody>
									</Table>
									<div className="full flex-row-right">
										<Button
											className="tenant-form-btn"
											onClick={() => setShowUnitModal(true)}
										>
											Add Unit
										</Button>
									</div>
								</Column>
							</Row>
							<Row className="mb-4">
								<Column>
									<Form.Label>Parking Slots</Form.Label>
									<Table className="tenant-table parkings">
										<thead>
											<tr className="text-sm">
												<th>Tower</th>
												<th>Level</th>
												<th>Slot No.</th>
												<th className="tenant-action-col"></th>
											</tr>
										</thead>
										<tbody>
											{parkings?.map((parking) => (
												<tr key={parking.index} className="text-sm">
													<td>{parking.tower}</td>
													<td>{parking.level}</td>
													<td>{parking.slot}</td>
													<td className="tenant-action-col">
														<a
															href="#"
															className="tenant-action-btn text-danger"
															onClick={(e) => {
																e.preventDefault();
																setParkingToDelete(parking);
															}}
														>
															<CloseOutlined sx={{ fontSize: 20 }} />
														</a>
													</td>
												</tr>
											))}
										</tbody>
									</Table>
									<div className="full flex-row-right">
										<Button
											className="tenant-form-btn"
											onClick={() => setShowParkingModal(true)}
										>
											Add Parking
										</Button>
									</div>
								</Column>
							</Row>
							<Row className="mb-4">
								<Column>
									<Form.Label>SPAs</Form.Label>
									<Table className="tenant-table spas">
										<thead>
											<tr className="text-sm">
												<th>Name</th>
												<th>Email</th>
												<th className="tenant-action-col"></th>
											</tr>
										</thead>
										<tbody>
											{spas?.map((spa) => (
												<tr key={spa.index} className="text-sm">
													<td>{spa.name}</td>
													<td>{spa.email}</td>
													<td className="tenant-action-col">
														<a
															href="#"
															className="tenant-action-btn text-danger"
															onClick={(e) => {
																e.preventDefault();
																setSpaToDelete(spa);
															}}
														>
															<CloseOutlined sx={{ fontSize: 20 }} />
														</a>
													</td>
												</tr>
											))}
										</tbody>
									</Table>
									<div className="full flex-row-right">
										<Button
											className="tenant-form-btn"
											onClick={() => setShowSpaModal(true)}
										>
											Add SPA
										</Button>
									</div>
								</Column>
							</Row>
							<div className="form-btn-container flex-row-right">
								{tenant && (
									<>
										<Button
											variant="outline-danger text-sm"
											disabled={actionLoading}
											onClick={() => setShowPasswordModal(true)}
										>
											Reset Password
										</Button>
										<Button
											variant="outline-danger ms-3 text-sm"
											disabled={actionLoading}
											onClick={() => setShowDeleteModal(true)}
										>
											Delete
										</Button>
									</>
								)}
								<Button
									type="submit"
									variant="primary ms-3 text-sm"
									disabled={actionLoading}
								>
									{saveLoading ? "Saving" : tenant ? "Save Changes" : "Save"}
								</Button>
							</div>
						</Form>
					</div>
				)}
			</Section>
			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Delete
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">
						Are you sure you want to delete this unit owner? All of their
						previous bookings will be deleted.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setShowDeleteModal(false)}
					>
						Close
					</Button>
					<Button
						variant="danger"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={deleteTenant}
					>
						{deleteLoading ? "Loading" : "Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={unitToDelete} onHide={() => setUnitToDelete(null)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Delete Unit
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">Are you sure you want to delete this unit?</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setUnitToDelete(null)}
					>
						Close
					</Button>
					<Button
						variant="danger"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={deleteUnit}
					>
						{deleteLoading ? "Loading" : "Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={parkingToDelete} onHide={() => setParkingToDelete(null)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Delete Parking Slot
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">
						Are you sure you want to delete this parking slot?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setParkingToDelete(null)}
					>
						Close
					</Button>
					<Button
						variant="danger"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={deleteParking}
					>
						{deleteLoading ? "Loading" : "Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={spaToDelete} onHide={() => setSpaToDelete(null)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Delete SPA
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">Are you sure you want to delete this SPA?</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setSpaToDelete(null)}
					>
						Close
					</Button>
					<Button
						variant="danger"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={deleteSpa}
					>
						{deleteLoading ? "Loading" : "Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showPasswordModal}
				onHide={() => setShowPasswordModal(false)}
			>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Reset Password
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={changePassword}>
					<Modal.Body className="pt-4 pb-4">
						<p className="text-sm">
							The unit owner will be asked to change their password when they
							log in for the first time.
						</p>
						<Form.Group>
							<div
								className="show-password-btn flex-row-right"
								onClick={() => setShowResetPassword(!showResetPassword)}
							>
								<span>{showResetPassword ? "Hide" : "Show"}</span>
							</div>
							<Form.Control
								required
								type={showResetPassword ? "text" : "password"}
								className="text-sm password-text"
								size="lg"
								placeholder="New Password"
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => setShowPasswordModal(false)}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="danger"
							className="modal-btn bold-text text-sm"
							disabled={actionLoading}
						>
							{passwordLoading ? "Loading" : "Reset"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showUnitModal} onHide={() => setShowUnitModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						New Unit
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={addUnit}>
					<Modal.Body className="pt-4 pb-4">
						<Row className="mb-4">
							<Column>
								<Form.Label>Tower</Form.Label>
								<Form.Select
									required
									className="text-sm capitalize"
									value={unitTower}
									onChange={(e) => setUnitTower(e.target.value)}
								>
									<option value=""></option>
									<option value="Bahamas">Bahamas</option>
									<option value="Boracay">Boracay</option>
									<option value="Miami">Miami</option>
									<option value="Maldives">Maldives</option>
									<option value="Maui">Maui</option>
									<option value="Positano">Positano</option>
									<option value="Rio East">Rio East</option>
									<option value="Rio West">Rio West</option>
									<option value="Santorini">Santorini</option>
									<option value="St. Tropez">St. Tropez</option>
								</Form.Select>
							</Column>
						</Row>
						<Row className="mb-4">
							<Column>
								<Form.Label>Unit</Form.Label>
								<Form.Control
									required
									type="text"
									className="text-sm uppercase"
									size="lg"
									value={unitNo}
									onChange={(e) => setUnitNo(e.target.value)}
								/>
							</Column>
						</Row>
						<Row className="mb-4">
							<Column>
								<Form.Label>Type</Form.Label>
								<Form.Control
									required
									type="text"
									className="text-sm"
									size="lg"
									value={unitType}
									onChange={(e) => setUnitType(e.target.value)}
								/>
							</Column>
						</Row>
						<Row className="mb-4">
							<Column>
								<Form.Label>Max. Guests</Form.Label>
								<Form.Control
									required
									type="number"
									className="text-sm"
									size="lg"
									value={unitGuests}
									onChange={(e) => setUnitGuests(e.target.value)}
								/>
							</Column>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => setShowUnitModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn bold-text text-sm"
							disabled={actionLoading}
						>
							Add
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showParkingModal} onHide={() => setShowParkingModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						New Parking Slot
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={addParking}>
					<Modal.Body className="pt-4 pb-4">
						<Row className="mb-4">
							<Column>
								<Form.Label>Tower</Form.Label>
								<Form.Select
									required
									className="text-sm"
									value={parkingTower}
									onChange={(e) => setParkingTower(e.target.value)}
								>
									<option value=""></option>
									<option value="Bahamas">Bahamas</option>
									<option value="Boracay">Boracay</option>
									<option value="Miami">Miami</option>
									<option value="Maldives">Maldives</option>
									<option value="Maui">Maui</option>
									<option value="Positano">Positano</option>
									<option value="Rio East">Rio East</option>
									<option value="Rio West">Rio West</option>
									<option value="Santorini">Santorini</option>
									<option value="St. Tropez">St. Tropez</option>
								</Form.Select>
							</Column>
						</Row>
						<Row className="mb-4">
							<Column>
								<Form.Label>Level</Form.Label>
								<Form.Select
									required
									className="text-sm"
									value={parkingLevel}
									onChange={(e) => setParkingLevel(e.target.value)}
								>
									<option value=""></option>
									<option value="Basement 1">Basement 1</option>
									<option value="Basement 2">Basement 2</option>
									<option value="Basement 3">Basement 3</option>
									<option value="Basement 4">Basement 4</option>
								</Form.Select>
							</Column>
						</Row>
						<Row className="mb-4">
							<Column>
								<Form.Label>Slot No.</Form.Label>
								<Form.Control
									required
									type="text"
									className="text-sm capitalize"
									size="lg"
									value={parkingSlot}
									onChange={(e) => setParkingSlot(e.target.value)}
								/>
							</Column>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => setShowParkingModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn bold-text text-sm"
							disabled={actionLoading}
						>
							Add
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showSpaModal} onHide={() => setShowSpaModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Add SPA
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={(e) => addSpa(e, items[selectedItem])}>
					<Modal.Body className="spa-search-modal pt-4 pb-4">
						<Form.Group>
							<Form.Label>SPA</Form.Label>
							<div className="spa-input-container">
								<Form.Control
									autoFocus
									required
									type="text"
									className="text-sm"
									size="lg"
									placeholder="SPA name or email"
									value={spaQuery}
									onChange={(e) => setSpaQuery(e.target.value)}
									onKeyDown={highlightSuggestion}
								/>
								<div
									className={`spa-suggestions-container ${
										!showSuggestions && "hidden"
									}`}
								>
									{loadingSuggestions ? (
										<Loader className="suggestions-loader white-bg" />
									) : (
										items?.map((item, index) => (
											<a
												key={index}
												className={`suggestion ellipsis ${
													selectedItem === index && "selected"
												}`}
												onMouseOver={handleSuggestionHover}
												onClick={(e) => addSpa(e, item)}
											>
												<span>
													{item.name} ({item.email})
												</span>
											</a>
										))
									)}
								</div>
							</div>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => setShowSpaModal(false)}
						>
							Cancel
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Tenant;
