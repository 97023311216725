import { Outlet } from "react-router-dom";
import TenantNavigation from "../components/TenantNavigation";

const TenantLayout = () => {
	return (
		<>
			<TenantNavigation />
			<Outlet />
		</>
	);
};

export default TenantLayout;
