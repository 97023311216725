import { Outlet } from "react-router-dom";
import SpaNavigation from "../components/SpaNavigation";

const SpaLayout = () => {
	return (
		<>
			<SpaNavigation />
			<Outlet />
		</>
	);
};

export default SpaLayout;
