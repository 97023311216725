import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

const Home = ({ access }) => {
	const navigate = useNavigate();

	useEffect(() => {
		if (access) {
			if (access.bookings_view) {
				navigate("/pmo/bookings");
			} else if (access.units) {
				navigate("/pmo/tenants");
			} else if (access.residents) {
				navigate("/pmo/residents");
			} else if (access.spas) {
				navigate("/pmo/spas");
			} else if (access.pool) {
				navigate("/pmo/guests/pool/cashier");
			} else if (access.pay_parking) {
				navigate("/pmo/parking");
			} else if (access.users) {
				navigate("/pmo/users");
			} else if (access.roles) {
				navigate("/pmo/roles");
			} else if (access.pool_scanner) {
				navigate("/pmo/guests/pool/scanner");
			} else if (access.verifier) {
				navigate("/pmo/bookings/verifier");
			} else if (access.entry) {
				navigate("/pmo/bookings/vehicles/basement");
			} else if (access.basement) {
				navigate("/pmo/bookings/vehicles/basement");
			}
		}
	}, [access]);

	return <Loader className="master-loader full-page white-bg" />;
};

export default Home;
