import { useEffect, useState } from "react";
import { FileUploadOutlined, MoreVert, ExpandMore } from "@mui/icons-material";
import Dropdown from "react-bootstrap/Dropdown";
import Container from "./Container";
import Row from "./Row";
import Column from "./Column";
import Form from "react-bootstrap/Form";

const GuestRow = ({
	index,
	locked,
	parkings,
	primaryWithParking,
	primaryParkingSlot,
	guests,
	setGuests,
	additionalErrors,
	activeIndex,
	setActiveIndex,
	deleteGuest,
	bucket,
	uuidv4,
	S3_BUCKET,
}) => {
	const [guestName, setGuestName] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].name
			: ""
	);
	const [guestAge, setGuestAge] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].age
			: ""
	);
	const [guestId, setGuestId] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].id
			: ""
	);
	const [guestIdUploading, setGuestIdUploading] = useState(false);
	const [guestIdName, setGuestIdName] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].idName
			: ""
	);
	const [guestIdProgress, setGuestIdProgress] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].idProgress
			: 0
	);
	const [guestVax, setGuestVax] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].vax
			: ""
	);
	const [guestVaxUploading, setGuestVaxUploading] = useState(false);
	const [guestVaxName, setGuestVaxName] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].vaxName
			: ""
	);
	const [guestVaxProgress, setGuestVaxProgress] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].vaxProgress
			: 0
	);
	const [belowMinHeight, setBelowMinHeight] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].belowMinHeight
			: locked
			? true
			: false
	);
	const [withVehicle, setWithVehicle] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].withVehicle
			: false
	);
	const [vehicleType, setVehicleType] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].vehicleType
			: "Car"
	);
	const [vehicleModel, setVehicleModel] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].vehicleModel
			: ""
	);
	const [vehiclePlate, setVehiclePlate] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].vehiclePlate
			: ""
	);
	const [withParking, setWithParking] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].withParking
			: false
	);
	const [parkingTower, setParkingTower] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].parkingTower
			: ""
	);
	const [parkingLevel, setParkingLevel] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].parkingLevel
			: ""
	);
	const [parkingSlot, setParkingSlot] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].parkingSlot
			: ""
	);
	const [parkingPayment, setParkingPayment] = useState(
		guests.filter((guest) => guest.index == index).length !== 0
			? guests.filter((guest) => guest.index == index)[0].parkingPayment
			: "Cash"
	);

	const handleFileInput = (
		e,
		updatePath,
		updateName,
		updateProgress,
		updateUploading
	) => {
		updateUploading(true);
		let filename = `${uuidv4()}.${e.target.files[0].name.split(".").pop()}`;
		updatePath(e.target.files[0]);
		updateName(filename);

		const params = {
			Body: e.target.files[0],
			Bucket: S3_BUCKET,
			Key: `bookings/${filename}`,
		};

		bucket
			.putObject(params)
			.on("httpUploadProgress", (evt) => {
				updateProgress(Math.round((evt.loaded / evt.total) * 100));
			})
			.send((err) => {
				if (err) console.log(err);
				else
					updatePath(
						`${process.env.REACT_APP_SERVER_URI}/bookings/images/${filename}`
					);
				updateUploading(false);
			});
	};

	useEffect(() => {
		const blank = !guestName && !guestAge && !guestId && !guestVax;
		if (guests.filter((guest) => guest.index === index).length !== 0) {
			if (!blank) {
				setGuests((prevGuests) => [
					...prevGuests.filter((prevGuest) => prevGuest.index !== index),
					{
						index: index,
						name: guestName,
						age: guestAge,
						belowMinHeight: belowMinHeight,
						id: guestId,
						idUploading: guestIdUploading,
						idName: guestIdName,
						idProgress: guestIdProgress,
						vax: guestVax,
						vaxUploading: guestVaxUploading,
						vaxName: guestVaxName,
						vaxProgress: guestVaxProgress,
						withVehicle: withVehicle,
						vehicleType: vehicleType,
						vehicleModel: vehicleModel,
						vehiclePlate: vehiclePlate,
						withParking: withParking,
						parkingTower: parkingTower,
						parkingLevel: parkingLevel,
						parkingSlot: parkingSlot,
						parkingPayment: parkingPayment,
						pool: guests.filter((guest) => guest.index == index)[0].pool
							? guests.filter((guest) => guest.index == index)[0].pool
							: [],
					},
				]);
			} else {
				setGuests((prevGuests) => [
					...prevGuests.filter((prevGuest) => prevGuest.index !== index),
				]);
			}
		} else {
			if (!blank) {
				setGuests((prevGuests) => [
					...prevGuests,
					{
						index: index,
						name: guestName,
						age: guestAge,
						belowMinHeight: belowMinHeight,
						id: guestId,
						idUploading: guestIdUploading,
						idName: guestIdName,
						idProgress: guestIdProgress,
						vax: guestVax,
						vaxUploading: guestVaxUploading,
						vaxName: guestVaxName,
						vaxProgress: guestVaxProgress,
						withVehicle: withVehicle,
						vehicleType: vehicleType,
						vehicleModel: vehicleModel,
						vehiclePlate: vehiclePlate,
						withParking: withParking,
						parkingTower: parkingTower,
						parkingLevel: parkingLevel,
						parkingSlot: parkingSlot,
						parkingPayment: parkingPayment,
						pool: [],
					},
				]);
			}
		}
	}, [
		guestName,
		guestAge,
		guestId,
		guestIdName,
		guestIdProgress,
		guestVax,
		guestVaxName,
		guestVaxProgress,
		belowMinHeight,
		withVehicle,
		vehicleType,
		vehicleModel,
		vehiclePlate,
		withParking,
		parkingTower,
		parkingLevel,
		parkingSlot,
		parkingPayment,
	]);

	return (
		<Container
			className={`wizard-input-group guest-group ${
				activeIndex === index && "active"
			}`}
		>
			<div className="guest-group-header">
				<Container>
					<Row>
						<Column className="guest-group-name text-sm col-10 bold-text">
							<div
								className="guest-group-toggle flex-row-left capitalize"
								onClick={() =>
									setActiveIndex(activeIndex === index ? null : index)
								}
							>
								<ExpandMore
									sx={{ fontSize: 28 }}
									className="guest-expand-icon"
								/>
								{activeIndex === index ? "" : guestName ? guestName : "(Empty)"}
							</div>
						</Column>
						<Column className="flex-row-right col-2">
							<Dropdown>
								<Dropdown.Toggle variant="text" className="guest-options-btn">
									<MoreVert sx={{ fontSize: 24 }} />
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item
										className="text-danger"
										onClick={() => deleteGuest(index)}
									>
										Delete
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Column>
					</Row>
				</Container>
			</div>
			<div className="guest-group-body">
				<Container>
					<Row>
						<Column>
							<label className="blue-text form-label mb-2 bold-text">
								Name
							</label>
							<input
								type="text"
								className={`form-control capitalize ${
									additionalErrors.includes("name" + index) && "border-danger"
								}`}
								value={guestName}
								onChange={(e) => setGuestName(e.target.value)}
							/>
						</Column>
					</Row>
					<Row className="mt-4">
						<Column>
							<label className="blue-text form-label mb-2 bold-text">Age</label>
							<input
								type="number"
								className={`form-control ${
									additionalErrors.includes("age" + index) && "border-danger"
								}`}
								value={guestAge}
								onChange={(e) => setGuestAge(e.target.value)}
							/>
						</Column>
					</Row>
					<Row className="flex-row-left mt-5">
						<Column>
							<span className="blue-text form-label inline">Below 3 feet</span>
						</Column>
						<Column className="align-right">
							<input
								type="checkbox"
								className="cbx hidden"
								disabled={locked}
								checked={belowMinHeight}
								id={`${index}BelowHeight`}
								onChange={() => setBelowMinHeight(!belowMinHeight)}
							/>
							<label htmlFor={`${index}BelowHeight`} className="lbl"></label>
						</Column>
					</Row>
					{locked && (
						<Row className="mt-4">
							<Column>
								<div className="extra-guest-warning full">
									<p className="label-md m-0">
										Note: Extra guests should be 3ft and below.
									</p>
								</div>
							</Column>
						</Row>
					)}
					{!belowMinHeight && (
						<Row className="mt-5">
							<Column>
								<label className="blue-text form-label mb-2 bold-text">
									Valid ID
								</label>
								<div
									className={`wizard-upload-preview flex-column ${
										additionalErrors.includes("id" + index) && "border-danger"
									}`}
									style={{
										background: guestId ? `url(${guestId})` : "#eee",
									}}
									onClick={() =>
										document.getElementById(`guestId${index}`).click()
									}
								>
									{!guestId && !guestIdUploading && (
										<FileUploadOutlined sx={{ fontSize: 24 }} />
									)}

									{guestIdUploading && (
										<>
											<div className="upload-progress-overlay" />
											<div
												className="upload-progress-bar"
												style={{
													width: `${
														guestIdProgress < 20 ? 20 : guestIdProgress
													}%`,
												}}
											/>
										</>
									)}
								</div>
								<input
									type="file"
									className="form-control hidden"
									id={`guestId${index}`}
									accept="image/png, image/jpeg"
									onChange={(e) =>
										handleFileInput(
											e,
											setGuestId,
											setGuestIdName,
											setGuestIdProgress,
											setGuestIdUploading
										)
									}
								/>
							</Column>
							<Column>
								<label className="blue-text form-label mb-2 bold-text">
									Vax Cert.
								</label>
								<div
									className={`wizard-upload-preview flex-column ${
										additionalErrors.includes("vax" + index) && "border-danger"
									}`}
									style={{
										background: guestVax ? `url(${guestVax})` : "#eee",
									}}
									onClick={() =>
										document.getElementById(`guestVax${index}`).click()
									}
								>
									{!guestVax && !guestVaxUploading && (
										<FileUploadOutlined sx={{ fontSize: 24 }} />
									)}

									{guestVaxUploading && (
										<>
											<div className="upload-progress-overlay" />
											<div
												className="upload-progress-bar"
												style={{
													width: `${
														guestVaxProgress < 20 ? 20 : guestVaxProgress
													}%`,
												}}
											/>
										</>
									)}
								</div>
								<input
									type="file"
									className="form-control hidden"
									id={`guestVax${index}`}
									accept="image/png, image/jpeg"
									onChange={(e) =>
										handleFileInput(
											e,
											setGuestVax,
											setGuestVaxName,
											setGuestVaxProgress,
											setGuestVaxUploading
										)
									}
								/>
							</Column>
						</Row>
					)}
					{!belowMinHeight && (
						<Row className="flex-row-left mt-5">
							<Column>
								<span className="blue-text form-label inline">
									With Vehicle
								</span>
							</Column>
							<Column className="align-right">
								<input
									type="checkbox"
									className="cbx hidden"
									checked={withVehicle}
									id={`${index}withVehicle`}
									onChange={() => setWithVehicle(!withVehicle)}
								/>
								<label htmlFor={`${index}withVehicle`} className="lbl"></label>
							</Column>
						</Row>
					)}
					{withVehicle && (
						<>
							<Row className="mt-4">
								<Column>
									<label className="blue-text form-label mb-2 bold-text">
										Type
									</label>
									<Form.Select
										className={`form-control ${
											additionalErrors.includes("vehicleType" + index) &&
											"border-danger"
										}`}
										value={vehicleType}
										onChange={(e) => setVehicleType(e.target.value)}
									>
										<option value="Car">Car</option>
										<option value="Motorcycle">Motorcycle</option>
									</Form.Select>
								</Column>
							</Row>
							<Row className="mt-4">
								<Column>
									<label className="blue-text form-label mb-2 bold-text">
										Make & Model
									</label>
									<input
										type="text"
										className={`form-control capitalize ${
											additionalErrors.includes("vehicleModel" + index) &&
											"border-danger"
										}`}
										value={vehicleModel}
										onChange={(e) => setVehicleModel(e.target.value)}
									/>
								</Column>
							</Row>
							<Row className="mt-4">
								<Column>
									<label className="blue-text form-label mb-2 bold-text">
										Plate No.
									</label>
									<input
										type="text"
										className={`form-control uppercase ${
											additionalErrors.includes("vehiclePlate" + index) &&
											"border-danger"
										}`}
										value={vehiclePlate}
										onChange={(e) => setVehiclePlate(e.target.value)}
									/>
								</Column>
							</Row>
							<Row className="flex-row-left mt-4">
								<Column>
									<span className="blue-text form-label inline">
										With Assigned Parking
									</span>
								</Column>
								<Column className="align-right">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={withParking}
										id={`${index}withParking`}
										onChange={() => setWithParking(!withParking)}
									/>
									<label
										htmlFor={`${index}withParking`}
										className="lbl"
									></label>
								</Column>
							</Row>
							{withParking ? (
								<>
									<Row className="mt-4">
										<Column>
											<label className="blue-text form-label mb-2 bold-text">
												Parking Slot
											</label>
											<Form.Select
												className={`text-sm m-0 form-control ${
													additionalErrors.includes("parkingSlot" + index) &&
													"border-danger"
												}`}
												size="lg"
												value={parkingSlot}
												onChange={(e) => setParkingSlot(e.target.value)}
											>
												<option value=""></option>
												{parkings.length !== 0 &&
													parkings.map((parking) => (
														<option
															key={parking.id}
															value={parking.id}
															disabled={
																guests.filter(
																	(guest) =>
																		guest.withParking === true &&
																		parseInt(guest.parkingSlot) === parking.id
																).length !== 0 ||
																(primaryWithParking &&
																	parseInt(primaryParkingSlot) === parking.id)
															}
														>
															{parking.tower} - {parking.level}, {parking.slot}
														</option>
													))}
											</Form.Select>
										</Column>
									</Row>
									{/* <Row className="mt-4">
										<Column>
											<label className="blue-text form-label mb-2 bold-text">
												Parking Tower
											</label>
											<Form.Select
												className={`text-sm m-0 form-control ${
													additionalErrors.includes("parkingTower" + index) &&
													"border-danger"
												}`}
												size="lg"
												value={parkingTower}
												onChange={(e) => setParkingTower(e.target.value)}
											>
												<option value=""></option>
												<option value="Bahamas">Bahamas</option>
												<option value="Boracay">Boracay</option>
												<option value="Miami">Miami</option>
												<option value="Maldives">Maldives</option>
												<option value="Maui">Maui</option>
												<option value="Positano">Positano</option>
												<option value="Rio East">Rio East</option>
												<option value="Rio West">Rio West</option>
												<option value="Santorini">Santorini</option>
												<option value="St. Tropez">St. Tropez</option>
											</Form.Select>
										</Column>
									</Row>
									<Row className="mt-4">
										<Column>
											<label className="blue-text form-label mb-2 bold-text">
												Parking Level
											</label>
											<Form.Select
												className={`text-sm m-0 form-control ${
													additionalErrors.includes("parkingLevel" + index) &&
													"border-danger"
												}`}
												size="lg"
												value={parkingLevel}
												onChange={(e) => setParkingLevel(e.target.value)}
											>
												<option value=""></option>
												<option value="Basement 1">Basement 1</option>
												<option value="Basement 2">Basement 2</option>
												<option value="Basement 3">Basement 3</option>
												<option value="Basement 4">Basement 4</option>
											</Form.Select>
										</Column>
									</Row>
									<Row className="mt-4">
										<Column>
											<label className="blue-text form-label mb-2 bold-text">
												Parking Slot
											</label>
											<input
												type="text"
												className={`form-control ${
													additionalErrors.includes("parkingSlot" + index) &&
													"border-danger"
												}`}
												value={parkingSlot}
												onChange={(e) => setParkingSlot(e.target.value)}
											/>
										</Column>
									</Row> */}
								</>
							) : (
								<Row className="mt-4">
									<Column>
										<label className="blue-text form-label mb-2 bold-text">
											Mode of Payment (Pay Parking)
										</label>
										<Form.Select
											className={`text-sm m-0 form-control ${
												additionalErrors.includes("parkingTower" + index) &&
												"border-danger"
											}`}
											size="lg"
											value={parkingPayment}
											onChange={(e) => setParkingPayment(e.target.value)}
										>
											<option value="Cash">Cash</option>
											<option value="Charge">Charge to Unit</option>
										</Form.Select>
									</Column>
								</Row>
							)}
						</>
					)}
				</Container>
			</div>
		</Container>
	);
};

export default GuestRow;
