import { React, useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { UserContext, UserProvider } from "./context/UserContext";
import { TenantContext, TenantProvider } from "./context/TenantContext";
import { ReportContext, ReportProvider } from "./context/ReportContext";
import { SpaContext, SpaProvider } from "./context/SpaContext";

import ScrollToTop from "./components/ScrollToTop";
import SnackbarProvider from "react-simple-snackbar";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./fonts/VarelaRound-Regular.ttf";
import "./index.css";

import Layout from "./pages/Layout";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Logout from "./components/Logout";
import Bookings from "./pages/Bookings";
import Booking from "./pages/Booking";
import PoolCashier from "./pages/PoolCashier";
import PoolAccess from "./pages/PoolAccess";
import PoolScanner from "./pages/PoolScanner";
import VehicleLogs from "./pages/VehicleLogs";
import VehicleScanner from "./pages/VehicleScanner";
import TenantBookings from "./pages/TenantBookings";
import TenantLayout from "./pages/TenantLayout";
import TenantHome from "./pages/TenantHome";
import TenantLogin from "./pages/TenantLogin";
import TenantBookingInfo from "./pages/TenantBookingInfo";
import TenantBookingForm from "./pages/TenantBookingForm";
import TenantBookingEdit from "./pages/TenantBookingEdit";
import TenantAnnouncements from "./pages/TenantAnnouncements";
import TenantSettings from "./pages/TenantSettings";
import TenantLogout from "./components/TenantLogout";

import Users from "./pages/Users";
import User from "./pages/User";
import Roles from "./pages/Roles";
import Role from "./pages/Role";
import Parking from "./pages/Parking";
import Verifier from "./pages/Verifier";
import BasementParking from "./pages/BasementParking";
import Guests from "./pages/Guests";
import Revisions from "./pages/Revisions";
import Revision from "./pages/Revision";
import SPAs from "./pages/SPAs";
import SPA from "./pages/SPA";
import Tenants from "./pages/Tenants";
import Tenant from "./pages/Tenant";
import Privacy from "./pages/Privacy";
import TermsPage from "./pages/TermsPage";
import Residents from "./pages/Residents";
import Resident from "./pages/Resident";

import SpaLayout from "./pages/SpaLayout";
import SpaHome from "./pages/SpaHome";
import SpaBookings from "./pages/SpaBookings";
import SpaBookingInfo from "./pages/SpaBookingInfo";
import SpaBookingForm from "./pages/SpaBookingForm";
import SpaBookingEdit from "./pages/SpaBookingEdit";
import SpaLogout from "./components/SpaLogout";
import Password from "./pages/Password";
import ParkingForm from "./pages/ParkingForm";
import ParkingSuccess from "./pages/ParkingSuccess";

export default function App() {
	const [user] = useContext(UserContext);
	const [tenantUser] = useContext(TenantContext);
	const [spaUser] = useContext(SpaContext);
	const [access, setAccess] = useState({});

	const getAccess = () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
		};
		fetch(`${process.env.REACT_APP_SERVER_URI}/users/me/access`, requestOptions)
			.then((res) => res.json())
			.then((res) => {
				setAccess(res.access);
			});
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			if (user) {
				getAccess();
			} else {
				setAccess({});
			}
		}, 500);
		return () => clearTimeout(timer);
	}, [user]);

	return (
		<>
			<BrowserRouter>
				<ScrollToTop />
				<Routes>
					<Route
						path="pmo/*"
						element={
							!user ? <Navigate to="/login" /> : <Layout access={access} />
						}
					>
						<Route index element={<Home access={access} />} />
						<Route path="bookings" element={<Bookings access={access} />} />
						<Route path="revisions" element={<Revisions access={access} />} />
						<Route path="visitors" element={<Guests access={access} />} />
						<Route path="tenants" element={<Tenants access={access} />} />
						<Route path="spas" element={<SPAs access={access} />} />
						<Route path="residents" element={<Residents access={access} />} />
						<Route path="users" element={<Users access={access} />} />
						<Route path="roles" element={<Roles access={access} />} />
						<Route path="users/:id" element={<User access={access} />} />
						<Route path="tenants/:id" element={<Tenant access={access} />} />
						<Route path="spas/:id" element={<SPA access={access} />} />
						<Route
							path="residents/:id"
							element={<Resident access={access} />}
						/>
						<Route path="roles/:id" element={<Role access={access} />} />
						<Route
							path="guests/pool/cashier"
							element={<PoolCashier access={access} />}
						/>
						<Route path="parking" element={<Parking access={access} />} />
						<Route
							path="pool-access"
							element={<PoolAccess access={access} />}
						/>
						<Route
							path="vehicles/logs"
							element={<VehicleLogs access={access} />}
						/>
						<Route path="bookings/:id" element={<Booking access={access} />} />
						<Route
							path="revisions/:id"
							element={<Revision access={access} />}
						/>
						<Route path="logout" element={<Logout />} />
					</Route>
					<Route
						path="/pmo/login"
						element={user ? <Navigate to="/pmo" /> : <Login />}
					/>
					<Route
						path="/pmo/guests/pool/scanner"
						element={<PoolScanner access={access} />}
					/>
					<Route
						path="/pmo/bookings/vehicles/scanner"
						element={<VehicleScanner access={access} />}
					/>
					<Route
						path="/pmo/bookings/verifier"
						element={<Verifier access={access} />}
					/>
					<Route
						path="/pmo/bookings/vehicles/basement"
						element={<BasementParking access={access} />}
					/>
					<Route path="/*" element={<TenantLayout />}>
						<Route
							index
							element={
								!tenantUser ? (
									<Navigate to={`/login${window.location.search}`} />
								) : (
									<TenantHome />
								)
							}
						/>
						<Route
							path="bookings"
							element={
								!tenantUser ? (
									<Navigate to={`/login${window.location.search}`} />
								) : (
									<TenantBookings />
								)
							}
						/>
						<Route
							path="announcements"
							element={
								!tenantUser ? (
									<Navigate to={`/login${window.location.search}`} />
								) : (
									<TenantAnnouncements />
								)
							}
						/>
						<Route
							path="settings"
							element={
								!tenantUser ? (
									<Navigate to={`/login${window.location.search}`} />
								) : (
									<TenantSettings />
								)
							}
						/>
						<Route
							path="logout"
							element={
								!tenantUser ? (
									<Navigate to={`/login${window.location.search}`} />
								) : (
									<TenantLogout />
								)
							}
						/>
						<Route
							path="bookings/:id"
							element={
								!tenantUser ? (
									<Navigate to={`/login${window.location.search}`} />
								) : (
									<TenantBookingInfo />
								)
							}
						/>
						<Route
							path="bookings/:id/edit"
							element={
								!tenantUser ? (
									<Navigate to={`/login${window.location.search}`} />
								) : (
									<TenantBookingEdit />
								)
							}
						/>
						<Route
							path="bookings/create"
							element={
								!tenantUser ? (
									<Navigate to={`/login${window.location.search}`} />
								) : (
									<TenantBookingForm />
								)
							}
						/>
					</Route>
					<Route path="spa/*" element={<SpaLayout />}>
						<Route
							index
							element={
								!spaUser ? (
									<Navigate to={`/login${window.location.search}`} />
								) : (
									<SpaHome />
								)
							}
						/>
						<Route
							path="bookings"
							element={
								!spaUser ? (
									<Navigate to={`/login${window.location.search}`} />
								) : (
									<SpaBookings />
								)
							}
						/>
						<Route
							path="logout"
							element={
								!spaUser ? (
									<Navigate to={`/login${window.location.search}`} />
								) : (
									<SpaLogout />
								)
							}
						/>
						<Route
							path="bookings/:id"
							element={
								!spaUser ? (
									<Navigate to={`/login${window.location.search}`} />
								) : (
									<SpaBookingInfo />
								)
							}
						/>
						<Route
							path="bookings/:id/edit"
							element={
								!spaUser ? (
									<Navigate to={`/login${window.location.search}`} />
								) : (
									<SpaBookingEdit />
								)
							}
						/>
						<Route
							path="bookings/create"
							element={
								!spaUser ? (
									<Navigate to={`/login${window.location.search}`} />
								) : (
									<SpaBookingForm />
								)
							}
						/>
					</Route>
					<Route
						path="login"
						element={
							tenantUser ? (
								<Navigate to={`/${window.location.search}`} />
							) : (
								<TenantLogin />
							)
						}
					/>
					<Route path="password/reset" element={<Password />} />
					<Route path="privacy" element={<Privacy />} />
					<Route path="terms" element={<TermsPage />} />
					<Route path="parking" element={<ParkingForm />} />
					<Route path="parking-success" element={<ParkingSuccess />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

ReactDOM.render(
	<UserProvider>
		<ReportProvider>
			<TenantProvider>
				<SpaProvider>
					<SnackbarProvider>
						<App />
					</SnackbarProvider>
				</SpaProvider>
			</TenantProvider>
		</ReportProvider>
	</UserProvider>,
	document.getElementById("root")
);
