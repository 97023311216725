import { useEffect, useState } from "react";
import { Download, ErrorOutline } from "@mui/icons-material";

const ReportItem = ({ report }) => {
	const [status, setStatus] = useState("Pending");

	const getStatus = async () => {
		const requestOptions = { method: "GET" };

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/reports/r/${report.id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setStatus(data.report.status);
			if (data.report.status === "Pending") {
				setTimeout(function () {
					getStatus();
				}, 1000);
			}
		}
	};

	useEffect(() => {
		getStatus();
	}, []);

	return (
		<div key={report.id} className="report-loader-item flex-row-left">
			<span className="report-loader-name ellipsis">{report.filename}</span>

			{status === "Pending" ? (
				<span className="report-loader-wheel" />
			) : status === "Failed" ? (
				<ErrorOutline
					sx={{ fontSize: 28 }}
					className="report-loader-error text-danger"
				/>
			) : (
				<>
					<a href={report.path} download className="report-loader-download">
						<Download sx={{ fontSize: 20 }} />
					</a>
				</>
			)}
		</div>
	);
};

export default ReportItem;
