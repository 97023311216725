import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import Section from "../components/Section";
import Container from "../components/Container";
import Row from "../components/Row";
import Column from "../components/Column";
import { useSnackbar } from "react-simple-snackbar";
import format from "date-fns/format";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/esm/Button";

const BasementParking = () => {
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [booking, setBooking] = useState([]);
	const [bookingNo, setBookingNo] = useState("");
	const [pay, setPay] = useState(0);
	const [free, setFree] = useState(0);
	const [available, setAvailable] = useState(0);
	const [valid, setValid] = useState(false);
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [parkingVehicle, setParkingVehicle] = useState("");
	const [showCheckoutModal, setShowCheckoutModal] = useState(false);
	const [showVerifyModal, setShowVerifyModal] = useState(false);
	const [guestToVerify, setGuestToVerify] = useState(null);
	const [actionLoading, setActionLoading] = useState(false);

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const getData = () => {
		fetch(`${process.env.REACT_APP_SERVER_URI}/bookings/vehicles/stats`)
			.then((res) => res.json())
			.then((res) => {
				setPay(res.pay);
				setFree(res.free);
				setAvailable(res.available_slots);
			});
	};

	const handleParkingModal = (vehicle_id) => {
		setParkingVehicle(vehicle_id);
		setShow(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				ref_no: bookingNo,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/vehicles/validate`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			if (data.is_valid) {
				setValid(true);
				successSnackbar(data.msg);
			} else {
				dangerSnackbar(data.msg);
				setValid(false);
			}
			setBooking(data.booking);
		}
		setLoading(false);
		setBookingNo("");
	};

	const checkout = async () => {
		setActionLoading(true);

		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				booking_id: booking.id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/checkout`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setBooking(data.booking);
			setShowCheckoutModal(false);
			successSnackbar(data.msg);
		}
		setActionLoading(false);
	};

	const verify = async () => {
		setActionLoading(true);

		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				guest_id: guestToVerify,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/guests/verify`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setBooking(data.booking);
			setShowVerifyModal(false);
			successSnackbar(data.msg);
		}
		setActionLoading(false);
	};

	const changeStatus = async (status, vehicle_id) => {
		setLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				vehicle_id: vehicle_id,
				status: status,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/vehicles/change_status`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			successSnackbar(data.msg);
			setBooking(data.booking);
			setPay(data.pay);
			setFree(data.free);
			console.log(data.booking);
		}
		setBookingNo("");
		setLoading(false);
	};

	const getParking = async (vehicle_id) => {
		setLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				vehicle_id: vehicle_id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/vehicles/get_parking`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			successSnackbar(data.msg);
			setBooking(data.booking);
			setPay(data.pay);
			setFree(data.free);
			setAvailable(data.available_slots);
		}
		setBookingNo("");
		setShow(false);
		setLoading(false);
	};

	useEffect(() => {
		document.title = "Azure | Vehicle Scanner";
		getData();
	}, []);

	const handleVerifyModal = (guest_id) => {
		setGuestToVerify(guest_id);
		setShowVerifyModal(true);
	};

	return (
		<>
			<Section className="full-page dark-bg flex-column pb-5">
				<form className="scanner-form flex-column pb-4" onSubmit={handleSubmit}>
					<input
						type="text"
						className="form-control align-center"
						placeholder="Reference No."
						value={bookingNo}
						autoFocus
						onChange={(e) => setBookingNo(e.target.value)}
					/>
				</form>
				<Container className="verifier-content">
					<div className="scanner-body white-bg">
						<Container className="scanner-info pt-5 ps-5 pe-5">
							{loading ? (
								<div className="scanner-loading flex-column">Loading</div>
							) : booking.length != 0 ? (
								<>
									<Row>
										<Column className="info-col align-center">
											<span
												className={`validity-text ${
													valid ? "blue-text" : "danger-text"
												}`}
											>
												{valid ? "VALID" : "INVALID"}
											</span>
										</Column>
									</Row>
									<Row>
										<Column className="info-col">
											<label className="form-label">Unit</label>
											<span className="info-text">
												{booking.unit.tower} {booking.unit.name}
											</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Check In</label>
											<span className="info-text block mb-2">
												{format(
													new Date(booking.arrival),
													"MMM dd, yyyy (hh:mm a)"
												)}
											</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Check Out</label>
											<span className="info-text block mb-2">
												{format(
													new Date(booking.departure),
													"MMM dd, yyyy (hh:mm a)"
												)}
											</span>
										</Column>
									</Row>
									{valid && (
										<>
											<Row className="mt-5 mb-3">
												<Column className="p-0">
													<span className="guests-header">
														Guests (with parking)
													</span>
												</Column>
											</Row>
											{booking.guests &&
												booking.guests
													.filter((guest) => guest.vehicle.length != 0)
													.sort((a, b) => a.name.localeCompare(b.name))
													.map((guest) => (
														<div className="guest-row mb-5">
															<Row
																key={guest.id}
																className="header-row flex-row-left"
															>
																<Column>
																	<span className="white-text">
																		{guest.name}
																	</span>
																</Column>
															</Row>
															<Row>
																<Column className="info-col">
																	<label className="form-label">Vehicle</label>
																	<span className="info-text">
																		{guest.vehicle.length !== 0
																			? `${guest.vehicle[0].model} (${guest.vehicle[0].type}) - ${guest.vehicle[0].plate_no}`
																			: "N/A"}
																	</span>
																</Column>
																<Column className="info-col">
																	<label className="form-label">Parking</label>
																	<span className="info-text">
																		{guest.vehicle.length !== 0 &&
																		guest.vehicle[0].with_parking
																			? `${guest.vehicle[0].parking_tower} - ${
																					guest.vehicle[0].parking_level &&
																					guest.vehicle[0].parking_level + ", "
																			  } ${guest.vehicle[0].parking_slot}`
																			: "N/A"}
																	</span>
																</Column>
															</Row>
														</div>
													))}
										</>
									)}
								</>
							) : (
								""
							)}
						</Container>
					</div>
				</Container>
			</Section>
			<div className="scanner-logout-container flex-row-center full">
				<Button
					variant="light"
					className="booking-action-btn"
					onClick={() => navigate("../pmo/logout")}
				>
					Log Out
				</Button>
			</div>
		</>
	);
};

export default BasementParking;
