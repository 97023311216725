import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { TenantContext } from "../context/TenantContext";
import { useSnackbar } from "react-simple-snackbar";
import { format } from "date-fns";

import Section from "../components/Section";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import { SearchOutlined, Add } from "@mui/icons-material";
import Loader from "../components/Loader";

const TenantSettings = () => {
	const navigate = useNavigate();
	const [tenantUser] = useContext(TenantContext);
	const [profile, fetchProfile] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [loading, setLoading] = useState(true);
	const [actionLoading, setActionLoading] = useState(false);
	const [resetModal, setResetModal] = useState(false);

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const getData = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": tenantUser.auth_token,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/me`,
			requestOptions
		);

		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
			navigate("/settings");
			setLoading(false);
		} else {
			fetchProfile(data.profile);
			fetchMeta(data.meta);
			setLoading(false);
		}
	};

	const changePassword = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": tenantUser.auth_token,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/me/password/generate`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setResetModal(false);
			successSnackbar(data.msg);
		}
		setActionLoading(false);
	};

	useEffect(() => {
		document.title = "Azure | Account Settings";
		getData();
	}, []);

	return (
		<>
			<Section className="tenant-content">
				{loading ? (
					<Loader className="booking-form-loader" />
				) : (
					<div>
						<div className="tenant-settings-item white-bg rounded-5 p-20 mb-20">
							<span className="text-md block bold-text">Account Name</span>
							<p className="label-md mb-20">
								To edit your personal info, please contact your PMO.
							</p>
							<span className="block text-sm">{profile.name}</span>
						</div>
						<div className="tenant-settings-item white-bg rounded-5 p-20 mb-20">
							<span className="text-md block bold-text">Email</span>
							<p className="label-md mb-20">
								To change your email, please contact your PMO.
							</p>
							<span className="block text-sm">{profile.email}</span>
						</div>
						<div className="tenant-settings-item white-bg rounded-5 p-20 mb-20">
							<span className="text-md block bold-text">Password</span>
							<p className="label-md mb-20">Change your password here.</p>
							<Button className="text-sm" onClick={() => setResetModal(true)}>
								Change Password
							</Button>
						</div>
					</div>
				)}
			</Section>
			<Modal show={resetModal} onHide={() => setResetModal(false)} centered>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Change Password
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="mb-0 text-sm">
						A password reset link will be sent to your email ({profile.email}).
						Would you like to proceed?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn bold-text text-sm"
						onClick={() => setResetModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={changePassword}
					>
						{actionLoading ? "Sending.." : "Send reset link"}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default TenantSettings;
