import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Section from "../components/Section";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { UserContext } from "../context/UserContext";
import Form from "react-bootstrap/Form";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import { Refresh } from "@mui/icons-material";

const SPAs = () => {
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [spas, fetchSpas] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [searchName, setSearchName] = useState(searchParams.get("name") || "");
	const [searchEmail, setSearchEmail] = useState(
		searchParams.get("email") || ""
	);

	const searchFields = [
		{ key: "name", value: searchName },
		{ key: "email", value: searchEmail },
	];

	const getData = () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
		};
		fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/spas${window.location.search}`,
			requestOptions
		)
			.then((res) => res.json())
			.then((res) => {
				fetchSpas(res.data);
				fetchMeta(res.meta);
				setLoading(false);
			});
	};

	const handleRowClick = (spaId) => {
		navigate(`/pmo/spas/${spaId}${window.location.search}`);
	};

	useEffect(() => {
		document.title = "Azure | SPAs";
		getData();
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			var newSearch = { ...searchParams };

			searchFields.map((field) => {
				if (field.value !== "") {
					newSearch[field.key] = field.value;
				} else {
					delete newSearch[field.key];
				}
			});
			setSearchParams(newSearch);
		}, 500);
		return () => clearTimeout(timer);
	}, [searchName, searchEmail]);

	useEffect(() => {
		getData();
	}, [searchParams]);

	return (
		<>
			<div className="page-header flex-row-left">
				<span className="back-label default-text">SPAs</span>
			</div>
			<Section className="content">
				<div className="content-header mb-2 flex-row-right">
					<Pagination
						meta={meta}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						searchFields={searchFields}
						className="pull-right flex-row-left"
					/>
					<Refresh
						className="refresh-btn blue-text ms-2 me-1 pointer"
						sx={{ fontSize: 28 }}
						onClick={getData}
					/>
					<Button
						variant="primary"
						className="booking-action-btn"
						onClick={() => navigate("/pmo/spas/create")}
					>
						Add
					</Button>
				</div>
				<Table className="content-table white-bg">
					<thead className="blue-text">
						<tr>
							<th>Name</th>
							<th>Email</th>
						</tr>
						<tr className="search-tr">
							<th>
								<Form.Control
									type="text"
									value={searchName}
									onChange={(e) => setSearchName(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchEmail}
									onChange={(e) => setSearchEmail(e.target.value)}
								/>
							</th>
						</tr>
					</thead>
					{loading ? (
						<Loader className="content-loader white-bg" />
					) : spas && spas.length != 0 ? (
						<tbody>
							{spas.map((spa) => (
								<tr key={spa.id} onClick={() => handleRowClick(spa.id)}>
									<td>{spa.name}</td>
									<td>{spa.email}</td>
								</tr>
							))}
						</tbody>
					) : (
						<div className="empty-content white-bg flex-column">
							<span>No records found.</span>
						</div>
					)}
				</Table>
			</Section>
		</>
	);
};

export default SPAs;
