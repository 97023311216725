import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ReportContext } from "../context/ReportContext";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Section from "../components/Section";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { UserContext } from "../context/UserContext";
import Form from "react-bootstrap/Form";
import { useSnackbar } from "react-simple-snackbar";
import Modal from "react-bootstrap/Modal";
import Container from "../components/Container";
import Row from "../components/Row";
import Column from "../components/Column";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import { Refresh } from "@mui/icons-material";

const Users = () => {
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [reports, setReports] = useContext(ReportContext);
	const [guest, setGuest] = useState(null);
	const [booking, setBooking] = useState(null);
	const [shift, setShift] = useState("");
	const [pass, setPass] = useState("");
	const [code, setCode] = useState("");
	const [series, setSeries] = useState("");
	const [reportFrom, setReportFrom] = useState("");
	const [reportTo, setReportTo] = useState("");
	const [showGuestModal, setShowGuestModal] = useState(false);
	const [showReportModal, setShowReportModal] = useState(false);
	const [swimbands, fetchSwimbands] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [loading, setLoading] = useState(true);
	const [modalLoading, setModalLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [searchName, setSearchName] = useState(searchParams.get("name") || "");
	const [searchPass, setSearchPass] = useState(
		searchParams.get("visitor_pass") || ""
	);
	const [searchCode, setSearchCode] = useState(searchParams.get("code") || "");
	const [searchShift, setSearchShift] = useState(
		searchParams.get("shift") || ""
	);
	const [searchDate, setSearchDate] = useState(searchParams.get("date") || "");

	const searchFields = [
		{ key: "name", value: searchName },
		{ key: "visitor_pass", value: searchPass },
		{ key: "code", value: searchCode },
		{ key: "shift", value: searchShift },
		{ key: "date", value: searchDate },
	];

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = () => {
		setLoading(true);
		fetch(
			`${process.env.REACT_APP_SERVER_URI}/guests/swimbands${window.location.search}`
		)
			.then((res) => res.json())
			.then((res) => {
				fetchSwimbands(res.data);
				fetchMeta(res.meta);
				setLoading(false);
			});
	};

	const searchGuest = async (e) => {
		e.preventDefault();
		setModalLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/guests/g/${pass}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
			setPass("");
		} else {
			setGuest(data.guest);
			setBooking(data.booking);
			setShift(data.shift);
		}
		setModalLoading(false);
	};

	const saveSwimband = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				guest_id: guest.id,
				code: code,
				series: series,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/guests/swimbands`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			getData();
			setGuest(null);
			setBooking(null);
			setShift(null);
			setPass("");
			setCode("");
			successSnackbar(data.msg);
		}
		setActionLoading(false);
	};

	const handleGuestModalClose = () => {
		setShowGuestModal(false);
		setGuest(null);
		setPass("");
		setCode("");
	};

	const generateReport = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				type: "pool",
				from: reportFrom,
				to: reportTo,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/reports/generate`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			console.log(data.report);
			setReports([...reports, data.report]);
			setShowReportModal(false);
			setReportFrom("");
			setReportTo("");
		}
		setActionLoading(false);
	};

	useEffect(() => {
		document.title = "Azure | Pool Cashier";
		getData();
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			var newSearch = { ...searchParams };

			searchFields.map((field) => {
				if (field.value !== "") {
					newSearch[field.key] = field.value;
				} else {
					delete newSearch[field.key];
				}
			});
			setSearchParams(newSearch);
		}, 500);
		return () => clearTimeout(timer);
	}, [searchName, searchCode, searchPass, searchShift, searchDate]);

	useEffect(() => {
		getData();
	}, [searchParams]);

	return (
		<>
			<div className="page-header flex-row-left">
				<span className="back-label default-text">Swim Bands</span>
			</div>
			<Section className="content">
				<div className="content-header mb-2 flex-row-right">
					<Pagination
						meta={meta}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						searchFields={searchFields}
						className="pull-right flex-row-left"
					/>
					<Refresh
						className="refresh-btn blue-text ms-2 me-1 pointer"
						sx={{ fontSize: 28 }}
						onClick={getData}
					/>
					<Button
						variant="primary"
						className="booking-action-btn"
						onClick={() => setShowGuestModal(true)}
					>
						Add
					</Button>
					<Button
						variant="primary"
						className="booking-action-btn"
						onClick={() => setShowReportModal(true)}
					>
						Export
					</Button>
				</div>
				<Table className="content-table white-bg">
					<thead className="blue-text">
						<tr>
							<th>Date</th>
							<th>Shift</th>
							<th>Guest</th>
							<th>Visitor's Pass</th>
							<th>Swim Band</th>
						</tr>
						<tr className="search-tr">
							<th>
								<Form.Control
									type="date"
									value={searchDate}
									onChange={(e) => setSearchDate(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchShift}
									onChange={(e) => setSearchShift(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchName}
									onChange={(e) => setSearchName(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchPass}
									onChange={(e) => setSearchPass(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchCode}
									onChange={(e) => setSearchCode(e.target.value)}
								/>
							</th>
						</tr>
					</thead>
					{loading ? (
						<Loader className="content-loader white-bg" />
					) : swimbands && swimbands.length != 0 ? (
						<tbody>
							{swimbands.map((swimband) => (
								<tr key={swimband.id}>
									<td>
										{format(new Date(swimband.access_date), "MMM dd, yyyy")}
									</td>
									<td>{swimband.access_time}</td>
									<td>{swimband.guest.name}</td>
									<td>{swimband.guest.visitor_pass}</td>
									<td>{swimband.code}</td>
								</tr>
							))}
						</tbody>
					) : (
						<div className="empty-content white-bg flex-column">
							<span>No records found.</span>
						</div>
					)}
				</Table>
			</Section>
			<Modal size="lg" show={showGuestModal} onHide={handleGuestModalClose}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Visitor's Pass
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={guest ? saveSwimband : searchGuest}>
					<Modal.Body className="pt-4 pb-4">
						{modalLoading ? (
							<div className="loading flex-column">Loading</div>
						) : guest ? (
							<Container>
								<Row className="mb-5">
									<Column>
										<label className="form-label">Guest</label>
										<span className="info-text">{guest.name}</span>
									</Column>
									<Column>
										<label className="form-label">Unit</label>
										<span className="info-text">
											{booking.unit.tower} {booking.unit.name}
										</span>
									</Column>
								</Row>
								<Row className="mb-5">
									<Column>
										<label className="form-label">Booking Ref No.</label>
										<span className="info-text">{booking.ref_no}</span>
									</Column>
									<Column>
										<label className="form-label">Current Shift</label>
										<span className="info-text">{shift.text}</span>
									</Column>
								</Row>
								<Row className="mb-5">
									<Column>
										<label className="form-label">Prebooked</label>
										<span className="info-text">
											{shift.prebooked ? "Yes" : "No"}
										</span>
									</Column>
									<Column>
										<label className="form-label">Mode of Payment</label>
										<span className="info-text">
											{shift.prebooked ? booking.payment_mode : "Cash"}
										</span>
									</Column>
								</Row>
								<Row className="mb-5">
									<Column>
										<label className="form-label">Series No.</label>
										<Form.Control
											required
											type="text"
											value={series}
											onChange={(e) => setSeries(e.target.value)}
											autoFocus
										/>
									</Column>
								</Row>
								<Row>
									<Column>
										<label className="form-label">Swim Band</label>
										<Form.Control
											required
											type="text"
											value={code}
											onChange={(e) => setCode(e.target.value)}
										/>
									</Column>
								</Row>
							</Container>
						) : (
							<div className="flex-column">
								<Form.Label>Visitor's Pass No.</Form.Label>
								<Form.Control
									required
									type="text"
									value={pass}
									onChange={(e) => setPass(e.target.value)}
									autoFocus
								/>
							</div>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={handleGuestModalClose}
						>
							Close
						</Button>
						{guest && (
							<Button
								type="submit"
								variant="primary"
								className="modal-btn bold-text text-sm"
								disabled={actionLoading}
							>
								{actionLoading ? "Loading" : "Save"}
							</Button>
						)}
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showReportModal} onHide={() => setShowReportModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Generate Report
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={generateReport}>
					<Modal.Body>
						<Container>
							<Row className="mb-5">
								<Column>
									<Form.Label>From</Form.Label>
									<Form.Control
										required
										type="date"
										value={reportFrom}
										onChange={(e) => setReportFrom(e.target.value)}
									/>
								</Column>
								<Column>
									<Form.Label>To</Form.Label>
									<Form.Control
										required
										type="date"
										value={reportTo}
										onChange={(e) => setReportTo(e.target.value)}
									/>
								</Column>
							</Row>
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => setShowReportModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="modal-btn bold-text text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Loading" : "Generate"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Users;
