import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Section from "../components/Section";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { UserContext } from "../context/UserContext";
import Form from "react-bootstrap/Form";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import { Refresh } from "@mui/icons-material";

const Roles = () => {
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [roles, fetchRoles] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [searchName, setSearchName] = useState(searchParams.get("name") || "");

	const searchFields = [{ key: "name", value: searchName }];

	const getData = () => {
		setLoading(true);
		fetch(
			`${process.env.REACT_APP_SERVER_URI}/users/roles${window.location.search}`
		)
			.then((res) => res.json())
			.then((res) => {
				fetchRoles(res.data);
				fetchMeta(res.meta);
				setLoading(false);
			});
	};

	const handleRowClick = (userId) => {
		navigate(`/pmo/roles/${userId}${window.location.search}`);
	};

	useEffect(() => {
		document.title = "Azure | Users";
		getData();
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			var newSearch = { ...searchParams };

			searchFields.map((field) => {
				if (field.value !== "") {
					newSearch[field.key] = field.value;
				} else {
					delete newSearch[field.key];
				}
			});
			setSearchParams(newSearch);
		}, 500);
		return () => clearTimeout(timer);
	}, [searchName]);

	useEffect(() => {
		getData();
	}, [searchParams]);

	return (
		<>
			<div className="page-header flex-row-left">
				<span className="back-label default-text">User Roles</span>
			</div>
			<Section className="content">
				<div className="content-header mb-2 flex-row-right">
					<Pagination
						meta={meta}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						searchFields={searchFields}
						className="pull-right flex-row-left"
					/>
					<Refresh
						className="refresh-btn blue-text ms-2 me-1 pointer"
						sx={{ fontSize: 28 }}
						onClick={getData}
					/>
					<Button
						variant="primary"
						className="booking-action-btn"
						onClick={() => navigate("/pmo/roles/create")}
					>
						Add
					</Button>
				</div>
				<Table className="content-table white-bg">
					<thead className="blue-text">
						<tr>
							<th>Role</th>
							<th></th>
						</tr>
						<tr className="search-tr">
							<th>
								<Form.Control
									type="text"
									value={searchName}
									onChange={(e) => setSearchName(e.target.value)}
								/>
							</th>
							<th></th>
						</tr>
					</thead>
					{loading ? (
						<Loader className="content-loader white-bg" />
					) : roles && roles.length != 0 ? (
						<tbody>
							{roles.map((role) => (
								<tr key={role.id} onClick={() => handleRowClick(role.id)}>
									<td>{role.name}</td>
									<td></td>
								</tr>
							))}
						</tbody>
					) : (
						<div className="empty-content white-bg flex-column">
							<span>No records found.</span>
						</div>
					)}
				</Table>
			</Section>
		</>
	);
};

export default Roles;
