import { forwardRef } from "react";
import { format } from "date-fns";

const ParkingReceipt = forwardRef(({ transaction }, ref) => {
	return (
		transaction && (
			<div ref={ref} className="parking-receipt full flex-column">
				<div className="receipt-content">
					<span
						className="receipt-guest-name"
						style={{ top: "78px", left: "-80px" }}
					>
						{transaction.guest_name}
					</span>

					<span style={{ top: "108px", left: "125px" }}>
						{transaction.tower_to_visit}
					</span>
					<span style={{ top: "123px", left: "125px" }}>
						{transaction.unit_to_visit}
					</span>
					<span style={{ top: "138px", left: "125px" }}>
						{transaction.parking_tower} -{" "}
						{transaction.parking_level &&
							transaction.parking_level.split(" ")[0][0] +
								transaction.parking_level.split(" ").at(-1) +
								", "}
						{transaction.parking_slot}
					</span>

					<span style={{ top: "194px", left: "-31px" }}>
						{transaction.vehicle_type === "Motorcycle" && "(MC) "}
						{transaction.vehicle_plate_no}
					</span>
					<span style={{ top: "213px", left: "-31px" }}>
						{format(new Date(transaction.start), "MM/dd/yyyy HH:mm")}
					</span>
					<span style={{ top: "231px", left: "-31px" }}>
						{format(new Date(transaction.end), "MM/dd/yyyy HH:mm")}
					</span>

					<span style={{ top: "194px", left: "125px" }}>
						₱{transaction.vatable_sales}
					</span>
					<span style={{ top: "213px", left: "125px" }}>
						₱{transaction.vat}
					</span>
					<span style={{ top: "231px", left: "125px" }}>
						₱{transaction.grand_total}
					</span>

					<span
						className="receipt-guest-name"
						style={{ top: "398px", left: "-80px" }}
					>
						{transaction.guest_name}
					</span>

					<span style={{ top: "427px", left: "125px" }}>
						{transaction.tower_to_visit}
					</span>
					<span style={{ top: "443px", left: "125px" }}>
						{transaction.unit_to_visit}
					</span>
					<span style={{ top: "457px", left: "125px" }}>
						{transaction.parking_tower} -{" "}
						{transaction.parking_level &&
							transaction.parking_level.split(" ")[0][0] +
								transaction.parking_level.split(" ").at(-1) +
								", "}
						{transaction.parking_slot}
					</span>
					<span style={{ top: "505px", left: "30px" }}>
						{transaction.vehicle_type === "Motorcycle" && "(MC) "}
						{transaction.vehicle_plate_no}
					</span>
					<span style={{ top: "525px", left: "-40px" }}>
						{format(new Date(transaction.start), "MM/dd/yyyy HH:mm")}
					</span>
					<span style={{ top: "525px", left: "125px" }}>
						{format(new Date(transaction.end), "MM/dd/yyyy HH:mm")}
					</span>
					<span style={{ top: "544px", left: "125px" }}>
						₱{transaction.grand_total}
					</span>
				</div>
			</div>
		)
	);
});

export default ParkingReceipt;
