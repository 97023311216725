import { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Section from "../components/Section";
import { useSnackbar } from "react-simple-snackbar";
import Row from "../components/Row";
import Column from "../components/Column";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { ArrowBackOutlined } from "@mui/icons-material";
import { UserContext } from "../context/UserContext";
import Modal from "react-bootstrap/Modal";

const SPA = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [loading, setLoading] = useState(true);
	const [actionLoading, setActionLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [passwordLoading, setPasswordLoading] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showPasswordModal, setShowPasswordModal] = useState(false);
	const [showResetPassword, setShowResetPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [spa, setSpa] = useState(null);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getSpa = () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
		};
		fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/spas/s/${id}`,
			requestOptions
		)
			.then((res) => res.json())
			.then((res) => {
				setSpa(res.spa);
				setLoading(false);
			});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		setSaveLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				name: name,
				email: email,
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/spas/s/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			if (data.created) {
				navigate(`/pmo/spas/${data.spa.id}${window.location.search}`);
			}
			setSpa(data.spa);
			successSnackbar(data.msg);
		}
		setActionLoading(false);
		setSaveLoading(false);
	};

	const changePassword = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		setPasswordLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				password: newPassword,
				temporary: true,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/spas/s/${id}/password`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			successSnackbar(data.msg);
			setShowPasswordModal(false);
			setNewPassword("");
		}
		setActionLoading(false);
		setPasswordLoading(false);
	};

	const deleteSpa = async () => {
		setActionLoading(true);
		setDeleteLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/spas/s/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			navigate(`/pmo/spas${window.location.search}`);
			successSnackbar(data.msg);
		}
		setShowDeleteModal(false);
		setActionLoading(false);
		setDeleteLoading(false);
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			getSpa();
		}
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		if (spa) {
			setName(spa.name);
			setEmail(spa.email);
		}
	}, [spa]);

	return (
		<>
			<div className="page-header flex-row-left">
				<Link
					to={`/pmo/spas${window.location.search}`}
					className="flex-row-left"
				>
					<ArrowBackOutlined
						className="back-icon blue-text me-2"
						sx={{ fontSize: 28 }}
					/>
					{loading ? (
						""
					) : (
						<span className="back-label default-text">
							{spa ? `SPAs > ${spa.name}` : "SPAs > Create New"}
						</span>
					)}
				</Link>
			</div>
			<Section className="content">
				{loading ? (
					<div className="content-loading flex-column">Loading</div>
				) : (
					<div className="info-container white-bg p-5">
						<Form onSubmit={handleSubmit}>
							<span className="section-title mb-3 block">SPA Info</span>
							<Row className="mb-4">
								<Column>
									<Form.Group>
										<Form.Label>Full Name</Form.Label>
										<Form.Control
											required
											type="text"
											className="text-sm capitalize"
											size="lg"
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
									</Form.Group>
								</Column>
							</Row>
							<Row className="mb-4">
								<Column>
									<Form.Group>
										<Form.Label>Email</Form.Label>
										<Form.Control
											required
											type="email"
											className="text-sm"
											size="lg"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</Form.Group>
								</Column>
							</Row>
							{!spa && (
								<Row className="mb-4">
									<Column>
										<Form.Group>
											<Form.Label>Temporary Password</Form.Label>
											<div>
												<div
													className="show-password-btn flex-row-right"
													onClick={() => setShowPassword(!showPassword)}
												>
													<span>{showPassword ? "Hide" : "Show"}</span>
												</div>
												<Form.Control
													required
													type={showPassword ? "text" : "password"}
													className="text-sm"
													size="lg"
													value={password}
													onChange={(e) => setPassword(e.target.value)}
												/>
											</div>
										</Form.Group>
									</Column>
								</Row>
							)}
							<div className="form-btn-container flex-row-right">
								{spa && (
									<>
										<Button
											variant="outline-danger text-sm"
											disabled={actionLoading}
											onClick={() => setShowPasswordModal(true)}
										>
											Reset Password
										</Button>
										<Button
											variant="outline-danger ms-3 text-sm"
											disabled={actionLoading}
											onClick={() => setShowDeleteModal(true)}
										>
											Delete
										</Button>
									</>
								)}
								<Button
									type="submit"
									variant="primary ms-3 text-sm"
									disabled={actionLoading}
								>
									{saveLoading ? "Saving" : spa ? "Save Changes" : "Save"}
								</Button>
							</div>
						</Form>
					</div>
				)}
			</Section>
			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Delete
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">Are you sure you want to delete this SPA?</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setShowDeleteModal(false)}
					>
						Close
					</Button>
					<Button
						variant="danger"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={deleteSpa}
					>
						{deleteLoading ? "Loading" : "Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showPasswordModal}
				onHide={() => setShowPasswordModal(false)}
			>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Reset Password
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={changePassword}>
					<Modal.Body className="pt-4 pb-4">
						<p className="text-sm">
							The SPA will be asked to change their password when they log in
							for the first time.
						</p>
						<Form.Group>
							<div
								className="show-password-btn flex-row-right"
								onClick={() => setShowResetPassword(!showResetPassword)}
							>
								<span>{showResetPassword ? "Hide" : "Show"}</span>
							</div>
							<Form.Control
								required
								type={showResetPassword ? "text" : "password"}
								className="text-sm password-text"
								size="lg"
								placeholder="New Password"
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => setShowPasswordModal(false)}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="danger"
							className="modal-btn bold-text text-sm"
							disabled={actionLoading}
						>
							{passwordLoading ? "Loading" : "Reset"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default SPA;
