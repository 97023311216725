import { useEffect, useState } from "react";
import Row from "../components/Row";
import Column from "../components/Column";
import { format } from "date-fns";

const GuestPool = ({ guest_id }) => {
	const [guest, setGuest] = useState(null);

	const getData = () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		};
		fetch(
			`${process.env.REACT_APP_SERVER_URI}/guests/by-id/${guest_id}`,
			requestOptions
		)
			.then((res) => res.json())
			.then((res) => {
				setGuest(res.guest);
			});
	};

	useEffect(() => {
		getData();
	}, [guest_id]);

	return (
		<div>
			<Row className="tenant-header-row">
				<Column>
					<span className="white-text">{guest?.name}</span>
				</Column>
			</Row>
			<Row className="mb-5">
				<Column className="tenant-info-col">
					{guest?.pool_access.length != 0 ? (
						guest?.pool_access.map((pool_access) => (
							<span key={pool_access.id} className="info-text block mb-1 mb-1">
								{format(new Date(pool_access.access_date), "MMM dd, yyyy")} (
								{pool_access.access_time})
							</span>
						))
					) : (
						<span className="info-text block mb-1 mb-1">None</span>
					)}
				</Column>
			</Row>
		</div>
	);
};

export default GuestPool;
