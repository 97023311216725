import { useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import Section from "../components/Section";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import { Refresh } from "@mui/icons-material";

const Revisions = () => {
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [bookings, fetchBookings] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [searchRef, setSearchRef] = useState(searchParams.get("ref") || "");
	const [searchName, setSearchName] = useState(searchParams.get("name") || "");
	const [searchUnit, setSearchUnit] = useState(searchParams.get("unit") || "");
	const [searchCreatedAt, setSearchCreatedAt] = useState(
		searchParams.get("created_at") || ""
	);
	const [searchStatus, setSearchStatus] = useState(
		searchParams.get("status") || ""
	);

	const statusClasses = {
		Pending: "pending",
		Confirmed: "confirmed",
		Rejected: "rejected",
	};

	const searchFields = [
		{ key: "ref", value: searchRef },
		{ key: "name", value: searchName },
		{ key: "unit", value: searchUnit },
		{ key: "created_at", value: searchCreatedAt },
		{ key: "status", value: searchStatus },
	];

	const getData = () => {
		setLoading(true);
		fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/revisions${window.location.search}`
		)
			.then((res) => res.json())
			.then((res) => {
				fetchBookings(res.data);
				fetchMeta(res.meta);
				setLoading(false);
			});
	};

	const handleRowClick = (bookingId) => {
		navigate(`/pmo/revisions/${bookingId}${window.location.search}`);
	};

	useEffect(() => {
		document.title = "Azure | Bookings";
		getData();
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			var newSearch = { ...searchParams };

			searchFields.map((field) => {
				if (field.value !== "") {
					newSearch[field.key] = field.value;
				} else {
					delete newSearch[field.key];
				}
			});
			setSearchParams(newSearch);
		}, 500);
		return () => clearTimeout(timer);
	}, [searchRef, searchName, searchUnit, searchCreatedAt, searchStatus]);

	useEffect(() => {
		getData();
	}, [searchParams]);

	return (
		<>
			<div className="page-header flex-row-left">
				<span className="back-label default-text">Bookings</span>
			</div>
			<Section className="content">
				<div className="content-header flex-row-right">
					<Pagination
						meta={meta}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						searchFields={searchFields}
						className="pull-right flex-row-left"
					/>
					<Refresh
						className="refresh-btn blue-text ms-2 pointer"
						sx={{ fontSize: 28 }}
						onClick={getData}
					/>
				</div>
				<Table className="content-table white-bg">
					<thead className="blue-text">
						<tr>
							<th>Ref No.</th>
							<th>Primary Guest</th>
							<th>Unit</th>
							<th>Date Requested</th>
							<th>Status</th>
						</tr>
						<tr className="search-tr">
							<th>
								<Form.Control
									type="text"
									value={searchRef}
									onChange={(e) => setSearchRef(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchName}
									onChange={(e) => setSearchName(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchUnit}
									onChange={(e) => setSearchUnit(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="date"
									onChange={(e) => setSearchCreatedAt(e.target.value)}
									value={searchCreatedAt}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchStatus}
									onChange={(e) => setSearchStatus(e.target.value)}
								/>
							</th>
						</tr>
					</thead>
					{loading ? (
						<Loader className="content-loader white-bg" />
					) : bookings && bookings.length != 0 ? (
						<tbody>
							{bookings.map((booking) => (
								<tr key={booking.id} onClick={() => handleRowClick(booking.id)}>
									<td>{booking.ref_no}</td>
									<td>{booking.primary_guest_name}</td>
									<td>
										{booking.unit.tower} - {booking.unit.name}
									</td>
									<td>
										{format(new Date(booking.created_at), "MMM dd, yyyy")}
									</td>
									<td>
										<span
											className={`bookings-status label-md bold-text white-text ${
												statusClasses[booking.revision_status]
											}`}
										>
											{booking.revision_status}
										</span>
									</td>
								</tr>
							))}
						</tbody>
					) : (
						<div className="empty-content white-bg flex-column">
							<span>No records found.</span>
						</div>
					)}
				</Table>
			</Section>
		</>
	);
};

export default Revisions;
