import { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Section from "../components/Section";
import { useSnackbar } from "react-simple-snackbar";
import Row from "../components/Row";
import Column from "../components/Column";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { ArrowBackOutlined } from "@mui/icons-material";
import { UserContext } from "../context/UserContext";
import Modal from "react-bootstrap/Modal";

const Role = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [loading, setLoading] = useState(true);
	const [actionLoading, setActionLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [role, setRole] = useState(null);
	const [name, setName] = useState("");
	const [bookingsView, setBookingsView] = useState(null);
	const [bookingsEdit, setBookingsEdit] = useState(null);
	const [bookingsCheckin, setBookingsCheckin] = useState(null);
	const [pool, setPool] = useState(null);
	const [poolScanner, setPoolScanner] = useState(null);
	const [verifier, setVerifier] = useState(null);
	const [entry, setEntry] = useState(null);
	const [basement, setBasement] = useState(null);
	const [parking, setParking] = useState(null);
	const [users, setUsers] = useState(null);
	const [roles, setRoles] = useState(null);
	const [units, setUnits] = useState(null);
	const [residents, setResidents] = useState(null);
	const [spas, setSpas] = useState(null);

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getRole = () => {
		fetch(`${process.env.REACT_APP_SERVER_URI}/users/roles/${id}`)
			.then((res) => res.json())
			.then((res) => {
				setRole(res.role);
				setLoading(false);
			});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		setSaveLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				name: name,
				bookings_view: bookingsView,
				bookings_edit: bookingsEdit,
				bookings_checkin: bookingsCheckin,
				pool: pool,
				pool_scanner: poolScanner,
				verifier: verifier,
				entry: entry,
				basement: basement,
				pay_parking: parking,
				users: users,
				roles: roles,
				units: units,
				residents: residents,
				spas: spas,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/users/roles/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			if (data.created) {
				navigate(`/pmo/roles/${data.role.id}${window.location.search}`);
			}
			setRole(data.role);
			successSnackbar(data.msg);
		}
		setActionLoading(false);
		setSaveLoading(false);
	};

	const deleteRole = async () => {
		setActionLoading(true);
		setDeleteLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/users/roles/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			navigate(`/pmo/roles${window.location.search}`);
			successSnackbar(data.msg);
		}
		setShowDeleteModal(false);
		setActionLoading(false);
		setDeleteLoading(false);
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			getRole();
		}
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		if (role) {
			setName(role.name);
			setBookingsView(role.bookings_view);
			setBookingsEdit(role.bookings_edit);
			setBookingsCheckin(role.bookings_checkin);
			setPool(role.pool);
			setPoolScanner(role.pool_scanner);
			setVerifier(role.verifier);
			setEntry(role.entry);
			setBasement(role.basement);
			setParking(role.pay_parking);
			setUsers(role.users);
			setRoles(role.roles);
			setUnits(role.units);
			setResidents(role.residents);
			setSpas(role.spas);
		}
	}, [role]);

	const handleBookingsEdit = () => {
		if (!bookingsEdit) {
			setBookingsView(true);
		}
		setBookingsEdit(!bookingsEdit);
	};

	const handleBookingsCheckin = () => {
		if (!bookingsEdit) {
			setBookingsView(true);
		}
		setBookingsCheckin(!bookingsCheckin);
	};

	const handleBookingsView = () => {
		if (bookingsView) {
			setBookingsEdit(false);
			setBookingsCheckin(false);
		}
		setBookingsView(!bookingsView);
	};

	return (
		<>
			<div className="page-header flex-row-left">
				<Link
					to={`/pmo/roles${window.location.search}`}
					className="flex-row-left"
				>
					<ArrowBackOutlined
						className="back-icon blue-text me-2"
						sx={{ fontSize: 28 }}
					/>
					{loading ? (
						""
					) : (
						<span className="back-label default-text">
							{role ? `Roles > ${role.name}` : "Roles > Create New"}
						</span>
					)}
				</Link>
			</div>
			<Section className="content">
				{loading ? (
					<div className="content-loading flex-column">Loading</div>
				) : (
					<div className="info-container white-bg p-5">
						<Form onSubmit={handleSubmit}>
							<span className="section-title mb-3 block">Role Info</span>
							<Row>
								<Column>
									<Form.Group className="mb-3">
										<Form.Label>Name</Form.Label>
										<Form.Control
											required
											type="text"
											className="text-sm"
											size="lg"
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
									</Form.Group>
								</Column>
								<Column />
							</Row>
							<span className="section-title mt-4 mb-3 block">Permissions</span>
							<Row className="flex-row-left mb-4">
								<Column className="align-right flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={bookingsView}
										id="bookingsView"
										onChange={handleBookingsView}
									/>
									<label htmlFor="bookingsView" className="lbl m-0" />
									<span className="text-sm ms-3">View Bookings</span>
								</Column>
								<Column className="align-right flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={bookingsEdit}
										id="bookingsEdit"
										onChange={handleBookingsEdit}
									/>
									<label htmlFor="bookingsEdit" className="lbl m-0" />
									<span className="text-sm ms-3">Approve/Reject Bookings</span>
								</Column>
								<Column className="align-right flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={bookingsCheckin}
										id="bookingsCheckin"
										onChange={handleBookingsCheckin}
									/>
									<label htmlFor="bookingsCheckin" className="lbl m-0" />
									<span className="text-sm ms-3">
										Check In/Check Out Guests
									</span>
								</Column>
							</Row>
							<Row className="flex-row-left mb-4">
								<Column className="align-right flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={pool}
										id="pool"
										onChange={() => setPool(!pool)}
									/>
									<label htmlFor="pool" className="lbl m-0" />
									<span className="text-sm ms-3">Manage Swimbands</span>
								</Column>
								<Column className="align-right flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={poolScanner}
										id="poolScanner"
										onChange={() => setPoolScanner(!poolScanner)}
									/>
									<label htmlFor="poolScanner" className="lbl m-0" />
									<span className="text-sm ms-3">Validate Swimbands</span>
								</Column>
								<Column className="align-right flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={verifier}
										id="verifier"
										onChange={() => setVerifier(!verifier)}
									/>
									<label htmlFor="verifier" className="lbl m-0" />
									<span className="text-sm ms-3">Verify Guests</span>
								</Column>
							</Row>
							<Row className="flex-row-left mb-4">
								<Column className="align-right flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={entry}
										id="entry"
										onChange={() => setEntry(!entry)}
									/>
									<label htmlFor="entry" className="lbl m-0" />
									<span className="text-sm ms-3">Validate Entry</span>
								</Column>
								<Column className="align-right flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={basement}
										id="basement"
										onChange={() => setBasement(!basement)}
									/>
									<label htmlFor="basement" className="lbl m-0" />
									<span className="text-sm ms-3">
										Validate Basement Parking
									</span>
								</Column>
								<Column className="align-right flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={parking}
										id="parking"
										onChange={() => setParking(!parking)}
									/>
									<label htmlFor="parking" className="lbl m-0" />
									<span className="text-sm ms-3">Manage Pay Parking</span>
								</Column>
							</Row>
							<Row className="flex-row-left mb-4">
								<Column className="align-right flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={users}
										id="users"
										onChange={() => setUsers(!users)}
									/>
									<label htmlFor="users" className="lbl m-0" />
									<span className="text-sm ms-3">Manage Users</span>
								</Column>
								<Column className="align-right flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={roles}
										id="roles"
										onChange={() => setRoles(!roles)}
									/>
									<label htmlFor="roles" className="lbl m-0" />
									<span className="text-sm ms-3">Manage Roles</span>
								</Column>
								<Column className="align-right flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={units}
										id="units"
										onChange={() => setUnits(!units)}
									/>
									<label htmlFor="units" className="lbl m-0" />
									<span className="text-sm ms-3">Manage Units & Owners</span>
								</Column>
							</Row>
							<Row className="flex-row-left">
								<Column className="align-right flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={residents}
										id="residents"
										onChange={() => setResidents(!residents)}
									/>
									<label htmlFor="residents" className="lbl m-0" />
									<span className="text-sm ms-3">Manage Residents</span>
								</Column>
								<Column className="align-right flex-row-left">
									<input
										type="checkbox"
										className="cbx hidden"
										checked={spas}
										id="spas"
										onChange={() => setSpas(!spas)}
									/>
									<label htmlFor="spas" className="lbl m-0" />
									<span className="text-sm ms-3">Manage SPAs</span>
								</Column>
								<Column />
							</Row>
							<div className="form-btn-container flex-row-right">
								{role && (
									<Button
										variant="outline-danger text-sm"
										disabled={actionLoading}
										onClick={() => setShowDeleteModal(true)}
									>
										Delete
									</Button>
								)}
								<Button
									type="submit"
									variant="primary ms-3 text-sm"
									disabled={actionLoading}
								>
									{saveLoading ? "Saving" : role ? "Save Changes" : "Save"}
								</Button>
							</div>
						</Form>
					</div>
				)}
			</Section>
			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Delete
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">Are you sure you want to delete this role?</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setShowDeleteModal(false)}
					>
						Close
					</Button>
					<Button
						variant="danger"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={deleteRole}
					>
						{deleteLoading ? "Loading" : "Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Role;
