import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import Section from "../components/Section";
import Container from "../components/Container";
import Row from "../components/Row";
import Column from "../components/Column";
import { useSnackbar } from "react-simple-snackbar";
import format from "date-fns/format";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/esm/Spinner";
import { Refresh } from "@mui/icons-material";

const PoolScanner = () => {
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [guest, setGuest] = useState([]);
	const [booking, setBooking] = useState([]);
	const [poolAccess, setPoolAccess] = useState([]);
	const [swimBand, setSwimBand] = useState("");
	const [stl, setStl] = useState(0);
	const [nonStl, setNonStl] = useState(0);
	const [valid, setValid] = useState(false);
	const [loading, setLoading] = useState(false);
	const [fetchingStl, setFetchingStl] = useState(false);
	const [fetchingNonStl, setFetchingNonStl] = useState(false);

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = () => {
		fetch(`${process.env.REACT_APP_SERVER_URI}/guests/pool/stats`)
			.then((res) => res.json())
			.then((res) => {
				setStl(res.stl);
				setNonStl(res.non_stl);
			});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				swim_band: swimBand,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/guests/pool/validate`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			if (data.is_valid) {
				setValid(true);
				setStl(data.stl);
				setNonStl(data.non_stl);
				successSnackbar(data.msg);
			} else {
				dangerSnackbar(data.msg);
				setValid(false);
			}
			setGuest(data.guest);
			setBooking(data.booking);
			setPoolAccess(data.pool_access);
		}
		setLoading(false);
		setSwimBand("");
	};

	const refreshStat = async (type, loader, updateState) => {
		if (loader) {
			loader(true);
		}
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/guests/pool/stats/${type}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			updateState(data.count);
		}
		if (loader) {
			loader(false);
		}
	};

	useEffect(() => {
		const interval = setInterval(async () => {
			await refreshStat("non-stl", null, setNonStl);
		}, 3000);

		return () => {
			clearInterval(interval);
		};
	}, [user]);

	useEffect(() => {
		document.title = "Azure | Pool Access Scanner";
		getData();
	}, []);

	return (
		<>
			<Section className="full-page dark-bg p-5">
				<Container>
					<Row>
						<Column className="stat">
							<div className="stat-container white-bg flex-row-left">
								<div className="half">
									<span className="stat-val default-text block">{stl}/600</span>
									<span className="stat-label blue-text bold-text">STL</span>
								</div>
								<div className="flex-row-right half">
									{fetchingStl ? (
										<span className="loader-wheel sm" />
									) : (
										<Refresh
											className="stat-refresh-btn blue-text pointer"
											sx={{ fontSize: 38 }}
											onClick={() => refreshStat("stl", setFetchingStl, setStl)}
										/>
									)}
								</div>
							</div>
						</Column>
						<Column className="stat">
							<div className="stat-container white-bg flex-row-left">
								<div className="half">
									<span className="stat-val default-text block">
										{nonStl}/200
									</span>
									<span className="stat-label blue-text bold-text">
										NON-STL
									</span>
								</div>
								<div className="flex-row-right half">
									{fetchingNonStl ? (
										<span className="loader-wheel sm" />
									) : (
										<Refresh
											className="stat-refresh-btn blue-text pointer"
											sx={{ fontSize: 38 }}
											onClick={() =>
												refreshStat("non-stl", setFetchingNonStl, setNonStl)
											}
										/>
									)}
								</div>
							</div>
						</Column>
					</Row>
				</Container>
				<Container className="scanner-content mt-4">
					<div className="scanner-body white-bg">
						<form
							className="scanner-form flex-column p-5"
							onSubmit={handleSubmit}
						>
							<input
								type="text"
								className="form-control align-center"
								placeholder="Swim Band"
								value={swimBand}
								autoFocus
								onChange={(e) => setSwimBand(e.target.value)}
							/>
						</form>
						<Container className="scanner-info ps-5 pe-5">
							{loading ? (
								<div className="scanner-loading flex-column">Loading</div>
							) : guest.length != 0 ? (
								<>
									<Row>
										<Column className="info-col align-center">
											<span
												className={`validity-text ${
													valid ? "blue-text" : "danger-text"
												}`}
											>
												{valid ? "VALID" : "INVALID"}
											</span>
										</Column>
									</Row>
									<Row>
										<Column className="info-col">
											<label className="form-label">Name</label>
											<span className="info-text">{guest.name}</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Unit</label>
											<span className="info-text">
												{booking.unit.tower} {booking.unit.name}
											</span>
										</Column>
									</Row>
									<Row className="mb-5">
										<Column className="info-col">
											<label className="form-label">Swim Band</label>
											<span className="info-text">{poolAccess.code}</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Swim Band Date</label>
											<span className="info-text">
												{format(
													new Date(poolAccess.access_date),
													"MMM dd, yyyy"
												)}
											</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Swim Band Shift</label>
											<span className="info-text">
												{poolAccess.access_time}
											</span>
										</Column>
									</Row>
								</>
							) : (
								""
							)}
						</Container>
					</div>
				</Container>
			</Section>
			<div className="scanner-logout-container flex-row-center full">
				<Button
					variant="light"
					className="booking-action-btn"
					onClick={() => navigate("../../pmo/logout")}
				>
					Log Out
				</Button>
			</div>
		</>
	);
};

export default PoolScanner;
