import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { TenantContext } from "../context/TenantContext";
import { useSnackbar } from "react-simple-snackbar";
import {
	format,
	startOfToday,
	differenceInDays,
	eachDayOfInterval,
} from "date-fns";
import { DateRange } from "react-date-range";
import Section from "../components/Section";
import Button from "react-bootstrap/esm/Button";
import Dropdown from "react-bootstrap/esm/Dropdown";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { SearchOutlined, Add, Share } from "@mui/icons-material";
import Loader from "../components/Loader";

const TenantBookings = () => {
	const navigate = useNavigate();
	const [tenantUser] = useContext(TenantContext);
	const [bookings, fetchBookings] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searching, setSearching] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [name, setName] = useState(searchParams.get("q") || "");
	const [shareModal, setShareModal] = useState(false);
	const [generatingLink, setGeneratingLink] = useState(false);
	const [tenant, setTenant] = useState(null);
	const [unit, setUnit] = useState("");
	const [dates, setDates] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: "selection",
		},
	]);

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="add-btn flex-row-center btn-primary"
		>
			{children}
		</a>
	));

	const statusClasses = {
		Pending: "pending",
		"For Revision": "pending",
		Confirmed: "confirmed",
		"Checked In": "checked-in",
		"Partially Checked In": "checked-in",
		"Checked Out": "checked-out",
		"Partially Checked Out": "checked-out",
		"Due for Check Out": "due",
		Expired: "expired",
		Rejected: "rejected",
		Cancelled: "cancelled",
	};

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": tenantUser.auth_token,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/bookings${window.location.search}`,
			requestOptions
		);

		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
			navigate("/bookings");
			setLoading(false);
		} else {
			fetchBookings(data.data);
			setTenant(data.tenant);
			fetchMeta(data.meta);
			setLoading(false);
			setSearching(false);
		}
	};

	useEffect(() => {
		document.title = "Azure | Bookings";
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (name) {
				setSearchParams({ q: name });
			} else {
				setSearchParams({});
			}
		}, 500);
		return () => clearTimeout(timer);
	}, [name]);

	useEffect(() => {
		getData();
	}, [searchParams]);

	return (
		<>
			<Button
				className="add-btn flex-row-center"
				onClick={() => {
					navigate(`/bookings/create${window.location.search}`);
				}}
			>
				<Add sx={{ fontSize: 20 }} />
				<span className="add-btn-label">New Booking</span>
			</Button>
			{/* <Dropdown drop="up" align="end">
				<Dropdown.Toggle as={CustomToggle}>
					<Add sx={{ fontSize: 20 }} />
					<span className="add-btn-label">New Booking</span>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item
						href="#"
						className="add-btn-sub flex-row-left"
						onClick={() => setShareModal(true)}
					>
						<Share className="add-btn-sub-icon" sx={{ fontSize: 18 }} />
						Share form link
					</Dropdown.Item>
					<Dropdown.Item
						href={`/bookings/create${window.location.search}`}
						className="add-btn-sub flex-row-left"
					>
						<Add className="add-btn-sub-icon" sx={{ fontSize: 18 }} />
						Create booking
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown> */}
			<Section className="tenant-content">
				<div className="tenant-search-container flex-row-left">
					<SearchOutlined className="search-icon" />
					<input
						type="text"
						className="form-control search-text tenant-search-text"
						value={name}
						onChange={(e) => setName(e.target.value)}
						placeholder="Search bookings"
					/>
				</div>
				{loading ? (
					<Loader className="booking-form-loader" />
				) : bookings.length != 0 ? (
					bookings.map((booking) => (
						<Link
							to={`/bookings/${booking.id}${window.location.search}`}
							key={booking.id}
						>
							<div to="/test" className="tenent-booking white-bg">
								<span className="tenant-booking-name default-text bold-text block">
									{booking.primary_guest_name}
								</span>
								<span className="tenant-booking-info default-text block">
									{booking.ref_no}
								</span>
								<span className="tenant-booking-info default-text">
									{booking.unit.tower} {booking.unit.name} |{" "}
									{format(new Date(booking.arrival), "MMM dd")} -{" "}
									{format(new Date(booking.departure), "MMM dd")}
								</span>
								<div className="tenant-booking-status-container flex-row-left">
									<div
										className={`tenant-booking-status ${
											statusClasses[booking.display_status]
										}`}
									></div>
									<span className="tenant-booking-info default-text">
										{booking.display_status}
									</span>
								</div>
							</div>
						</Link>
					))
				) : (
					<div className="empty-container flex-column">
						<span>No records found.</span>
					</div>
				)}
			</Section>
			<Modal show={shareModal} onHide={() => setShareModal(false)} centered>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Share Form Link
					</Modal.Title>
				</Modal.Header>
				<Form>
					<Modal.Body className="pt-4 pb-4">
						<p className="label-md">
							This will generate a link that you can send to your guests and
							allow them to fill out the Guest Advise Form.
						</p>
						<Row className="mb-20">
							<Col>
								<Form.Label>Unit</Form.Label>
								<Form.Select
									className="form-control"
									defaultValue={unit}
									onChange={(e) => setUnit(e.target.value)}
								>
									<option value="" disabled></option>
									{tenant?.tenant_units?.map((tenant_unit) => (
										<option key={tenant_unit.id} value={tenant_unit.unit.id}>
											{tenant_unit.unit.tower} {tenant_unit.unit.name}
										</option>
									))}
								</Form.Select>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Label>Dates</Form.Label>
								<DateRange
									editableDateInputs={false}
									onChange={(item) => setDates([item.selection])}
									moveRangeOnFirstSelection={false}
									className="calendar-picker full"
									minDate={startOfToday()}
									ranges={dates}
									startDatePlaceholder={null}
									endDatePlaceholder={null}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => setShareModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="modal-btn bold-text text-sm"
							disabled={generatingLink}
						>
							{generatingLink ? "Generating.." : "Generate Link"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default TenantBookings;
