import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import coloredLogo from "../assets/logo_colored.png";
import { NavLink } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import {
	DirectionsCarOutlined,
	GroupOutlined,
	ListAltOutlined,
	CreateOutlined,
	PointOfSaleOutlined,
	LockOutlined,
	QrCodeScannerOutlined,
	AccountBoxOutlined,
	AccountCircleOutlined,
	AdminPanelSettingsOutlined,
	ApartmentOutlined,
	PoolOutlined,
} from "@mui/icons-material";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/Modal";

const Navigation = ({ access }) => {
	const [user] = useContext(UserContext);
	const [showResetPassword, setShowResetPassword] = useState(false);
	const [newPassword, setNewPassword] = useState("");
	const [passwordLoading, setPasswordLoading] = useState(false);
	const [showPasswordModal, setShowPasswordModal] = useState(false);

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const changePassword = async (e) => {
		e.preventDefault();
		setPasswordLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				password: newPassword,
				temporary: false,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/users/u/${user.user_id}/password`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			successSnackbar(data.msg);
			setShowPasswordModal(false);
		}
		setPasswordLoading(false);
	};

	useEffect(() => {
		if (access) {
			setShowPasswordModal(access.temp_pw);
		}
	}, [access]);

	return (
		<>
			<div className="sidebar">
				<div className="sidebar-header flex-row-center">
					<img src={coloredLogo} className="sidebar-logo" />
				</div>
				<div className="sidebar-body">
					{access && access.bookings_view && (
						<NavLink to="/pmo/bookings" className="sidebar-nav-item">
							<ListAltOutlined
								className="nav-icon me-3"
								sx={{ fontSize: 22 }}
							/>
							Bookings
						</NavLink>
					)}
					{access && access.bookings_edit && (
						<NavLink to="/pmo/revisions" className="sidebar-nav-item">
							<CreateOutlined className="nav-icon me-3" sx={{ fontSize: 22 }} />
							Revisions
						</NavLink>
					)}
					{access && access.bookings_checkin && (
						<NavLink to="/pmo/visitors" className="sidebar-nav-item">
							<AccountBoxOutlined
								className="nav-icon me-3"
								sx={{ fontSize: 22 }}
							/>
							Visitor's Pass
						</NavLink>
					)}
					{access && access.pool && (
						<NavLink to="/pmo/guests/pool/cashier" className="sidebar-nav-item">
							<PointOfSaleOutlined
								className="nav-icon me-3"
								sx={{ fontSize: 22 }}
							/>
							Pool Cashier
						</NavLink>
					)}
					{access && access.pool_scanner && (
						<NavLink to="/pmo/pool-access" className="sidebar-nav-item">
							<PoolOutlined className="nav-icon me-3" sx={{ fontSize: 22 }} />
							Pool Access
						</NavLink>
					)}
					{access && access.pay_parking && (
						<NavLink to="/pmo/parking" className="sidebar-nav-item">
							<DirectionsCarOutlined
								className="nav-icon me-3"
								sx={{ fontSize: 22 }}
							/>
							Pay Parking
						</NavLink>
					)}
					{access && access.units && (
						<NavLink to="/pmo/tenants" className="sidebar-nav-item">
							<ApartmentOutlined
								className="nav-icon me-3"
								sx={{ fontSize: 22 }}
							/>
							Units & Owners
						</NavLink>
					)}
					{access && access.spas && (
						<NavLink to="/pmo/spas" className="sidebar-nav-item">
							<AdminPanelSettingsOutlined
								className="nav-icon me-3"
								sx={{ fontSize: 22 }}
							/>
							SPAs
						</NavLink>
					)}
					{access && access.residents && (
						<NavLink to="/pmo/residents" className="sidebar-nav-item">
							<AccountCircleOutlined
								className="nav-icon me-3"
								sx={{ fontSize: 22 }}
							/>
							Residents
						</NavLink>
					)}
					{access && access.users && (
						<NavLink to="/pmo/users" className="sidebar-nav-item">
							<GroupOutlined className="nav-icon me-3" sx={{ fontSize: 22 }} />
							Users
						</NavLink>
					)}
					{access && access.roles && (
						<NavLink to="/pmo/roles" className="sidebar-nav-item">
							<LockOutlined className="nav-icon me-3" sx={{ fontSize: 22 }} />
							Roles
						</NavLink>
					)}
					{access && access.pool_scanner && (
						<NavLink to="/pmo/guests/pool/scanner" className="sidebar-nav-item">
							<QrCodeScannerOutlined
								className="nav-icon me-3"
								sx={{ fontSize: 22 }}
							/>
							Pool Scanner
						</NavLink>
					)}
					{access && access.verifier && (
						<NavLink to="/pmo/bookings/verifier" className="sidebar-nav-item">
							<QrCodeScannerOutlined
								className="nav-icon me-3"
								sx={{ fontSize: 22 }}
							/>
							Verifier
						</NavLink>
					)}
					{access && (access.basement || access.entry) && (
						<NavLink
							to="/pmo/bookings/vehicles/basement"
							className="sidebar-nav-item"
						>
							<QrCodeScannerOutlined
								className="nav-icon me-3"
								sx={{ fontSize: 22 }}
							/>
							Entry/Basement
						</NavLink>
					)}
					{/* <div className="branding flex-column full">
						<span className="opacity-5">
							Powered by{" "}
							<a href="https://mycondopal.com" className="bold-text">
								Condopal
							</a>
						</span>
					</div> */}
				</div>
			</div>
			<Dropdown>
				<div className="header-user flex-column">
					<Dropdown.Toggle variant="primary" className="user-icon flex-column">
						<span className="user-initials white-text bold-text">
							{user && user.name[0]}
						</span>
					</Dropdown.Toggle>
				</div>
				<Dropdown.Menu>
					<Dropdown.Item href="/pmo/logout">Log out</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			<Modal
				show={showPasswordModal}
				onHide={() => setShowPasswordModal(false)}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Change Password
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={changePassword}>
					<Modal.Body className="pt-4 pb-4">
						<Form.Group>
							<div
								className="show-password-btn flex-row-right"
								onClick={() => setShowResetPassword(!showResetPassword)}
							>
								<span>{showResetPassword ? "Hide" : "Show"}</span>
							</div>
							<Form.Control
								required
								type={showResetPassword ? "text" : "password"}
								className="text-sm password-text"
								size="lg"
								placeholder="New Password"
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							type="submit"
							variant="primary"
							className="modal-btn bold-text text-sm"
							disabled={passwordLoading}
						>
							{passwordLoading ? "Loading" : "Change"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Navigation;
